import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SupportService } from 'src/app/services/user-support.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-support',
  templateUrl: './user-support.component.html',
  styleUrls: ['./user-support.component.sass']
})
export class UserSupportComponent implements OnInit {
  user = '';
  currentDate = new Date().toLocaleString();
  subject = '';
  description = '';
  attachment: File | null = null;
  company = 2;

  subjects: { id: number; supportSubject: string }[] = [];
  successMessage: string | null = null;
  errorMessage: string | null = null;

  @ViewChild('supportForm') supportForm: NgForm | undefined;

  constructor(
    private authService: AuthService,
    private supportService: SupportService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser()?.email || 'Usuário não encontrado';
    this.loadSubjects();
  }

  loadSubjects(): void {
    this.supportService.getSubjects().subscribe({
      next: (subjects) => (this.subjects = subjects),
      error: () => (this.errorMessage = 'Não foi possível carregar os assuntos. Tente novamente mais tarde.')
    });
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    const allowedTypes = [
      'image/png',
      'image/jpeg',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain', 
      'application/rtf'
    ];
  
    if (file && allowedTypes.includes(file.type)) {
      this.attachment = file;
      this.showSnackBar({ message: 'Arquivo anexado com sucesso!', isError: false });
    } else {
      this.removeAttachment();
      this.showSnackBar({ message: 'Tipo de arquivo não suportado. Envie um arquivo PNG, JPG, PDF, Word, TXT ou RTF.', isError: true });
    }
  }
  
  removeAttachment(): void {
    this.attachment = null;
    (document.getElementById('fileInput') as HTMLInputElement).value = '';
    this.showSnackBar({ message: 'Arquivo removido com sucesso!', isError: false });
  }

  submitForm(): void {
    const currentUser = this.authService.getCurrentUser();
    if (!currentUser) return;

    const requestData = {
      userId: currentUser.id,
      supportSubjectId: Number(this.subject),
      description: this.description,
      file: this.attachment,
      company: this.company,
      reportedDate: new Date().toISOString()
    };

    this.supportService.sendSupportRequest(requestData).subscribe({
      next: () => {
        this.showSnackBar({ message: 'Formulário enviado com sucesso!', isError: false });
        this.publishSubject();
        this.resetForm();
      },
      error: () => {
        this.showSnackBar({ message: 'Erro ao enviar o formulário. Tente novamente mais tarde.', isError: true });
      }
    });
  }

  private publishSubject(): void {
    const selectedSubject = this.subjects.find(sub => sub.id === Number(this.subject));
    if (selectedSubject) {
      this.supportService.publishSubject(selectedSubject.supportSubject).subscribe({
        next: () => console.log('Assunto enviado para o publisherUrl'),
        error: (error) => console.error('Erro ao publicar assunto:', error)
      });
    }
  }

  private showSnackBar({ message, isError }: { message: string; isError: boolean }): void {
    const config: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: isError ? 'failure-bar-container' : 'success-bar-container'
    };
    this.snackBar.open(message, 'Fechar', config);
  }

  resetForm(): void {
    this.supportForm?.resetForm();
    this.subject = '';
    this.description = '';
    this.attachment = null;
  }
}
