import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ISuggestion } from '../interfaces/models/IEffectiveness';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, finalize } from 'rxjs/operators';

export interface AnalyticalFilters {
  causeGroup: string;
  city: string;
  cluster: string;
  day: string;
  family: string;
  group: string;
  isAccumulated: boolean;
  isPead: boolean;
  month: string;
  net: string;
  regional: string;
  state: string;
  subcluster: string;
  year: string;
}

export interface ActivityFilters {
  month: string;
  day: string;
  year: string;
  causeGroup: string;
  city: string;
  cluster: string;
  family: string;
  group: string;
  net: string;
  regional: string;
  state: string;
  subcluster: string;
  isAccumulated: boolean;
  isPead: boolean;
  isSuggestion: boolean;
}

export interface SuggestionFilters {
  [key: string]: string | boolean | number;
}

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {

  private filtersSubject = new BehaviorSubject<any>({});
  filtersActives = this.filtersSubject.asObservable();

  private suggestionSubject = new BehaviorSubject<ISuggestion[]>([]);
  suggestionActivities = this.suggestionSubject.asObservable();
  
  private locationSubject = new BehaviorSubject<{ lat: number; lng: number; zoom: number } | null>(null);
  location$ = this.locationSubject.asObservable();

  private buttonClickedSubject = new BehaviorSubject<boolean>(false);
  buttonClicked$ = this.buttonClickedSubject.asObservable(); 


  private suggestionDataSubject = new BehaviorSubject<any[]>([]);
  suggestionData$ = this.suggestionDataSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private totalPagesSubject = new BehaviorSubject<number>(1);
  totalPages$ = this.totalPagesSubject.asObservable();

  constructor(private http: HttpClient) { } 

  getAllSuggestions(page: number, filters: ActivityFilters): void {
    this.loadingSubject.next(true);
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}/vandalism/suggestion`;

    const body = this.mapSuggestionFilters(filters, page);
    console.log(filters);

    this.http
      .post<any>(url, body)
      .pipe(
        catchError(() => {
          this.loadingSubject.next(false);
          return of(null);
        }),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((response) => {
        if (response) {
          console.log(response.body);
          this.suggestionDataSubject.next(response.body);
          this.totalPagesSubject.next(response.pagesNumber || 1);
        }
      });
  }

  private mapSuggestionFilters(filters: ActivityFilters, page: number): SuggestionFilters {
    return {
      suggestion: filters.causeGroup || 'PEAD',
      regionals: filters.regional || '',
      directors: filters.group || '',
      cities: filters.city || '',
      states: filters.state || '',
      clusters: filters.cluster || '',
      subclusters: filters.subcluster || '',
      pages: page, // Adicione outros valores fixos ou dinâmicos
    };
  }

  setLocation(lat: number, lng: number, zoom:number) {
    if(lat != -15.8517 && lng != -48.5799)
    {
      this.locationSubject.next({ lat, lng, zoom });
    }
  }

  getLocation() {
    return this.locationSubject.value;
  }

  clearLocation() {  
    const lat:number =  -15.8517; 
    const lng:number =  -48.5799;
    const zoom:number = 4;

    this.locationSubject.next({ lat: lat, lng: lng, zoom: zoom });
  }

  setSuggestions(suggestion:ISuggestion[]){
    this.suggestionSubject.next(suggestion);    
  }

  getSuggestions()
  {
    return this.suggestionActivities;
  }
  
  setFilters(filters: any) {
    // Mapeia os filtros para a estrutura esperada
    const mappedFilters: AnalyticalFilters = {
      causeGroup: filters.causeGroup || '',
      city: filters.cities || '',
      cluster: filters.clusters || '',
      day: filters.days || '',
      family: filters.families || '',
      group: filters.directors || '',
      isAccumulated: filters.isAccumulated || false,
      isPead: filters.isPead || true,
      month: filters.months || '',
      net: filters.nets || '',
      regional: filters.regionals || '',
      state: filters.states || '',
      subcluster: filters.subclusters || '',
      year: filters.years || ''
    };

    this.filtersSubject.next(mappedFilters);    
  }
  
  getFilters() {
    return this.filtersSubject.value;
  }   

  setButtonClicked(value: boolean): void {
    this.buttonClickedSubject.next(value);
  }

  // Métodos para limpar os valores dos BehaviorSubjects
  resetFilters(): void {
    this.filtersSubject.next({});
  }

  resetSuggestions(): void {
    this.suggestionSubject.next([]);
  }

  resetLocation(): void {
    this.locationSubject.next(null);
  }

  resetButtonClicked(): void {
    this.buttonClickedSubject.next(false);
  }

  resetAll(): void {
    this.resetFilters();
    this.resetSuggestions();
    this.resetLocation();
    this.resetButtonClicked();
  }
}
