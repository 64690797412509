<div class="container">
  <h2 class="ticket-title ">Suporte</h2>

  <table class="table" style="margin-top: 20px;">
    <thead>
      <tr>
        <th>Usuário</th>
        <th>Anexo</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ user }}</td>
        <td style="width: 50%;">
          <div class="file-upload">
            <label for="fileInput" class="btn btn-secondary upload-button">
              <i class="bi bi-upload"></i> Escolher arquivo
            </label>
            <input 
              id="fileInput" 
              type="file" 
              (change)="onFileSelected($event)" 
              hidden 
            />
            <span *ngIf="attachment">{{ attachment.name }}</span>
            <button 
              *ngIf="attachment" 
              class="btn btn-danger btn-sm" 
              (click)="removeAttachment()"
            >
              Remover
            </button>
          </div>
        </td>        
      </tr>
    </tbody>
  </table>

  <form #supportForm="ngForm" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="subject">Assunto</label>
      <select 
        id="subject" 
        class="form-control" 
        [(ngModel)]="subject" 
        name="subject"
        required
        #subjectField="ngModel">
        <option value="" disabled selected>Selecione o assunto</option>
        <option *ngFor="let subject of subjects" [value]="subject.id">
          {{ subject.supportSubject }}
        </option>
      </select>
      <div *ngIf="subjectField.invalid && subjectField.touched" class="alert alert-danger">
        O campo assunto é obrigatório.
      </div>
    </div>  

    <div class="form-group">
      <label for="description">Descrição</label>
      <textarea style="height: 150px;"
        id="description" 
        class="form-control" 
        [(ngModel)]="description"
        name="description"
        required
        #descriptionField="ngModel">
      </textarea>
      <div *ngIf="descriptionField.invalid && descriptionField.touched" class="alert alert-danger">
        O campo descrição é obrigatório.
      </div>
    </div>

    <button class="btn btn-primary right-align" type="submit" [disabled]="!subject || !description || subjectField.invalid || descriptionField.invalid">
      Enviar
    </button>    
  </form>
</div>
