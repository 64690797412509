import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { initialImageAnalyticalData } from "src/app/interfaces/dtos/ImageAnalytical";
import { FilterService, Namespace } from "src/app/services/filter.service";
import { ImageVerificationService } from "src/app/services/image-verification.service";
import { families } from "src/app/utils/constants";
import { Formatter } from "src/app/utils/formatter.util";
import { GenerateDate } from "src/app/utils/generateDate.util";
import { hasGroupName, getMapped } from "../utils/mapped-values";
import { TechnicianReportType } from "src/app/services/publisher.service";
import { ReportTableModalComponent } from "./components/report-table-modal/report-table-modal.component";
import { ReportImagesModalComponent } from "./components/report-images-modal/report-images-modal.component";
import { TechnicianImageTransformStrategy } from "./strategy/TechnicianImageTransformStrategy";
import { slideInOutAnimation, fadeInOutAnimation } from "src/app/animations/animations";
import { SmartTableRow } from "src/app/components/smart-table/smart-table.component";
import { icons } from "src/app/utils/icons";
import { defaultFilters, InputFilter } from "../interfaces/InputFilter";

// Essas são as visões disponivies nessa tela.
export type ViewGraphContent = 
  'analyticalTicketsByDirectors' |
  'analyticalTicketsByTechnicians' |
  'analyticalTicketsByMonths' |
  'analyticalPhotosByValidations' |
  'analyticalReport';

@Component({
  selector: 'app-analitico-imags',
  templateUrl: './analitico.component.html',
  styleUrls: ['./analitico.component.scss'],
  animations: [slideInOutAnimation, fadeInOutAnimation]
})
export class AnaliticoImageComponent implements OnInit {

  private NAMESPACE: Namespace = 'imageAnalytical';

  @Input() width = 50;
  @Output() filterClick = new EventEmitter<string>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  data: BehaviorSubject<{ title: string, datasets: number[][], labels: string[], legends: string[] }> = new BehaviorSubject({
    title: null,
    datasets: [],
    labels: [],
    legends: []
  });

  tableData: BehaviorSubject<SmartTableRow[]> = new BehaviorSubject([]);
  tableTotalPage: BehaviorSubject<number> = new BehaviorSubject(15);

  selectedTechnian: BehaviorSubject<string> = new BehaviorSubject(null);

  tickets: BehaviorSubject<Record<string, string>[]> = new BehaviorSubject([]); 
  barChartData = { datasets: [], labels: [] };

  view: BehaviorSubject<ViewGraphContent> = new BehaviorSubject('analyticalTicketsByDirectors');
  filters = initialImageAnalyticalData;
  activeFilters: InputFilter;
  dropdownFilters = new BehaviorSubject<Record<string, string[]>>(defaultFilters);
  selectedFilters = new BehaviorSubject<Record<string, string[]>>(defaultFilters);
  selectedDirectors: BehaviorSubject<string[]> = new BehaviorSubject([]);
  loading: boolean = true;
  error: boolean  = false;
  isFoundContent: boolean = false;

  families=families;
  icons=icons;
  barWidthProportion = '100%';
  colors = [ '#808080', '#FF0000', '#8B0000', '#000000'];
  requestWrapperConfig = {
    chart: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary height-chart-image-verification',
  }

  isDivVisible: boolean = false;

  constructor(
    private filterService: FilterService,
    private imageVerificationService: ImageVerificationService,
    private technicianImageTransform: TechnicianImageTransformStrategy,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    const namespace = this.NAMESPACE;
    const { startDate, endDate } = GenerateDate.getDates();

    this.filterService.setDefaultFilters<InputFilter>({
      namespace,
      defaultFilters: {
        startDate: Formatter.formatDataPickerDate(startDate),
        endDate: Formatter.formatDataPickerDate(endDate),
        ...this.getDefaultFilters()
      }
    });

    this.filterService.getFiltersObservable<InputFilter>({ namespace })
      .pipe(debounceTime(500))
      .subscribe((af) => {
        this.activeFilters = af;
        this.fetchData(af, this.view.value)
      });
  }

  options: ChartOptions = {
    responsiveAnimationDuration: 0,
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    onClick: (event, activeElements: Array<any>) => {

      if (activeElements.length > 0) {
        if (this.isCurrentView('analyticalTicketsByTechnicians')) {
          let firstElement = activeElements[0];
          let elementIndex = firstElement._index;
          let label = this.barChartData.labels[elementIndex];
          let labelUpper = label.toUpperCase();
          let technicianId = labelUpper;
          this.selectedTechnian.next(technicianId);
          this.openTableModal({ ...this.activeFilters, technicianIds: technicianId });
        }

        if (this.isCurrentView('analyticalPhotosByValidations')) {
          let firstElement = activeElements[0];
          let elementIndex = firstElement._index;
          let label = this.barChartData.labels[elementIndex];
          let labelUpper = label.toUpperCase();
          let validation = labelUpper;
          this.openTableModal({ ...this.activeFilters, validations: validation });
        }
      }
    },    
    layout: {
      padding: {
        top: 15,
        left: -5,
        right: -5,
        bottom: 0
      }
    },
    scales: { 
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: true,
          beginAtZero: true,
          callback: (value: string) => {            
            if (hasGroupName(value)) {
              return getMapped({ key: 'groups', type: 'shortName', value });
            }

            return value;
          }
        }
      }],
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true
          },
          gridLines: {
            display: false
          },
        }
     ] 
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const activeData = this.data.value;
          const dataset = data.datasets[tooltipItem.datasetIndex];

          const value = dataset.data[tooltipItem.index];  
          const legends = activeData.legends; 
          
          if (tooltipItem.datasetIndex % 2 === 0) {
            return `${legends[0]}: ${value}`;
          } else {
            const percentage = activeData.datasets[tooltipItem.index][2];
            return `${legends[1]} (${legends[2]}): ${value} (${percentage}%)`;
          }
        }
      }
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        color: 'black',
        offset: -4,
        font: { weight: 'bold', size: 10 },
      }
    }
  };

  ngOnInit(): void {}

  openReportModal = (type: TechnicianReportType) => {
    this.loading = true;
    this.error = false;

    this.imageVerificationService.getAnalyticalImage(this.activeFilters, 'analyticalReport')
      .subscribe((response) => {
        this.loading = false;
        this.error = false
        this.tickets.next(response.tickets);
        const modalRef = this.modalService.open(ReportImagesModalComponent);
        modalRef.componentInstance.loading = this.loading;
        modalRef.componentInstance.activeFilters = this.activeFilters;
        modalRef.componentInstance.ticketsAll = this.tickets;
        modalRef.componentInstance.type = type;

        if (this.selectedTechnian.value) {
          const technician = this.selectedTechnian.value;
          const company = this.tickets.value.find(({ technicianId }) => technicianId === technician)?.company;
          modalRef.componentInstance.selectedCompanies = [{ value: company }];
          modalRef.componentInstance.selectedTechnicianIds = [{ value: technician }];
          this.selectedTechnian.next(null);
        }
      });
  }

  exportReportModal = (event: any) => {
    this.openReportModal('worst');
  }

  openTableModal(activeFilters: InputFilter) {
    if (this.isCurrentView('analyticalPhotosByValidations')) {
      this.loading = true;
      this.error = false;
      this.imageVerificationService.getAnalyticalTechnicianTable({ ...activeFilters }, 0, 15, null, null, null)
        .subscribe((response) => {
          this.activeFilters = activeFilters;
          this.loading = false;
          const modalRef = this.modalService.open(ReportTableModalComponent, { size: 'xl' });
          const data = this.technicianImageTransform.transform(response.data);
          modalRef.componentInstance.validators = this.technicianImageTransform.validators();
          modalRef.componentInstance.data = this.tableData;
          modalRef.componentInstance.totalPages = this.tableTotalPage;
          modalRef.componentInstance.handlePageChange = this.handlePageChange;
          modalRef.componentInstance.exportData = this.exportReportModal;
          this.tableData.next(data);
          this.tableTotalPage.next(response.totalPage);
      });
    }

    if (this.isCurrentView('analyticalTicketsByTechnicians')) {
      this.loading = true;
      this.error = false;
  
      this.imageVerificationService.getAnalyticalTechnicianTable({ ...activeFilters }, 0, 15, null, null, null)
        .subscribe((response) => {
          this.activeFilters = activeFilters;
          this.loading = false;
          const modalRef = this.modalService.open(ReportTableModalComponent, { size: 'xl' });
          const data = this.technicianImageTransform.transform(response.data);
          modalRef.componentInstance.validators = this.technicianImageTransform.validators();
          modalRef.componentInstance.data = this.tableData;
          modalRef.componentInstance.totalPages = this.tableTotalPage;
          modalRef.componentInstance.handlePageChange = this.handlePageChange;
          modalRef.componentInstance.exportData = this.exportReportModal;
          this.tableData.next(data);
          this.tableTotalPage.next(response.totalPage);
        });
      }
  }

  fetchData(activeFilters: InputFilter, view: ViewGraphContent) {
    this.activeFilters = activeFilters;
    this.loading = true;
    return this.imageVerificationService.getAnalyticalImage(activeFilters, view)
      .subscribe(({ data, filters }) => {
        this.loading = false;
        this.error = false;
        this.isFoundContent = (data?.datasets.length > 0);
        this.data.next(data);
        this.dropdownFilters.next(filters);
        this.createBarChart(data);
      }, (error) => {
        this.loading = false;
        this.error = true;
        this.isFoundContent = false;
      });
  }

  isCurrentView(view: ViewGraphContent): boolean {
    return this.view.value === view;
  }

  createBarChart(data: any) {
  
    if (data.datasets.length > 0) {
      let { datasets: dataset, labels } = data;


      if (this.view.value === 'analyticalPhotosByValidations') {
        dataset = dataset[0].map((d) => [d])
      }

      const maxColumns = dataset ? Math.max(...dataset.map(d => d.length)) : 0;
      const barQuantity = dataset.reduce((a, c) => a + c.length, 0) 
      const datasets = [];

      let treatedLabels = labels;

      this.updateBarProportion(barQuantity);

      const chartType = (dataIndex: number): 'line' | 'bar' => {
        if (maxColumns > 2 && dataIndex === (maxColumns - 1)) return 'line';
        return 'bar';
      }

      for (let i = 0; i < maxColumns; i++) {
        const data = (dataset ?? []).map(d => d[i] ?? 0)
        const colorsArr = Array(20).fill(this.colors[i % this.colors.length])
        
        let params = {     
          data: data,
          type: chartType(i),
          order: i + 1,
          fill: colorsArr,
          backgroundColor: colorsArr,
          hoverBackgroundColor: colorsArr,
          borderColor: this.colors[i % this.colors.length]
        }

        if (chartType(i) === 'line') {
          const lastDatasetAdded = datasets.pop();

          datasets.push({
            ...lastDatasetAdded,
            datalabels: {
              align: 'end',
              anchor: 'end',
              font: {
                weight: 'bold',
                size: 10
              },
              formatter: (value, context) => {
                const index = context.dataIndex;
                if (value !== 0 && data[index] !== 0) {
                  return `${value} (${data[index]}%)`
                }
                if (value !== 0 && data[index] === 0) {
                  return `${value}`
                }
                return value;
              }
            },
          });
        } else {
          datasets.push(params);
        }
      }

      this.barChartData = { datasets, labels: treatedLabels }
    }
  };

  cleanFilters() {
    const dropdownKeys = Object.keys(this.dropdownFilters.value);
    const selectedItems = dropdownKeys.reduce((a, c) => {
      a[c] = [];
      return a;
    }, {});
    
    selectedItems["causes"] = ['VANDALISMO'];
    this.selectedFilters.next({ ...selectedItems });
    this.filterService.updateMultipleFilters({ namespace: this.NAMESPACE, newFilters: {
      startDate: this.activeFilters.startDate,
      endDate: this.activeFilters.endDate,
      ...this.getDefaultFilters()
    } });

    this.cdr.detectChanges();
  }

  handlePageChange = (event: { page: number, size: number, search: string, isPreview: boolean, sortBy: string[], sortDirection: string[] }): void => {
    const page = event.page || 1;

    this.imageVerificationService.getAnalyticalTechnicianTable({ ...this.activeFilters }, page, event.size, event.search, event.sortBy, event.sortDirection)
      .subscribe((response) => {
        const data = this.technicianImageTransform.transform(response.data);
        this.tableData.next(data);
        this.tableTotalPage.next(response.totalPage);
      });
  }

  toggleView(value: ViewGraphContent) {
    this.view.next(value);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  };

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getYesterday();
    return { startDate, endDate };
  }

  getDefaultFilters(): InputFilter {
    return {
      causes: 'VANDALISMO',
      regionals: '',
      directors: '',
      states: '',
      clusters: '',
      subclusters: '',
      cities: ''
    }
  }

  setView(newView: ViewGraphContent) {
    this.view.next(newView);
    this.filterService.actionChange({ namespace: this.NAMESPACE });
  }

  updateChartSize() {
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  updateBarProportion(barQuantity: number) {
    this.barWidthProportion = `100%`
    this.updateChartSize();
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    this.filterService.updateFilters<InputFilter>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }
}