import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RequestResult } from 'src/app/interfaces/RequestContext';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-policy-pop-up',
  templateUrl: './policy-pop-up.component.html',
  styleUrls: []
})
export class PolicyPopUpComponent implements OnInit {
  
  @Input() policy: string;
  userId: number;
  buttonState : boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.userId = this.authService.getCurrentUser().id;
  }

  ngOnInit(): void {
    
  }

  toggleButton() : void {
    this.buttonState = !this.buttonState;
  }

  savePolicy() : void {
    if (this.buttonState === true){
      this.userService.updatePolicy(this.userId).subscribe(
        _response => {
          const result: RequestResult = {
            status: 200,
            message: 'Você aceitou os termos.',
            isError: false,
            styleClass: 'success-bar-container'
          };
          this.openPopUpResponse(result);
          this.authService.updateUser({policy : true})
          this.closeModal();
        },
        (error) => {
          const result: RequestResult = {
            status: error.status,
            message: 'Você aceitou os termos.',
            isError: true,
            styleClass: 'failure-bar-container'
          };
          this.openPopUpResponse(result);
          this.authService.logout;
        }
      )
    }
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

}
