import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filter-default',
  templateUrl: './filter-default.component.html',
  styleUrls: []
})
export class FilterDefaultComponent implements OnInit {
  
  @Input() icon: IconDefinition;
  @Input() dropdownList: Array<{ show: string, value: string }> = [];
  @Input() filterTitle: string;
  @Input() key: string;
  @Input() isSingle: boolean = false;
  @Output() event = new EventEmitter<string>();

  @Input() selectedList = new Set<string>();
  selectedListChanges = new Subject<Set<string>>();

  constructor() { }

  ngOnInit(): void {
    this.selectedListChanges.subscribe(() => {
      this.onChange();
    });
  }

  ngOnDestroy() {
    this.selectedListChanges.unsubscribe();
  }

  toggleSelection(item: string) {
    this.selectedList.has(item) 
      ? this.selectedList.delete(item) 
      : this.selectedList.add(item);

    this.selectedListChanges.next(this.selectedList);
  }

  toggleSelectionSingle(item: string) {
    this.selectedList.clear();
    this.selectedList.add(item);
    this.selectedListChanges.next(this.selectedList);
  }

  isSelected(item: string): boolean {
    if (this.dropdownList.length === 1) {
      return true;
    }

    return this.selectedList.has(item);
  }

  onChange() {
    const items = Array.from(this.selectedList).join(',');
    this.event.emit(`${this.key}:${items}`);
  }
}