<div>
  <div *ngIf="loadingModal" class="loading-screen">
    <div class="loading-animation">
      <div class="spinner-container">        
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>
  <div class="row h-20 selects-row">

    <div class="col-lg-4 col-md-4">
      <app-data-picker-default
        [dates]="getRangeDate()"
        (event)="onChange($event)"
        [backgroundColor]="'#ffffff'" 
        [color]="'#333333'"
      >
      </app-data-picker-default>
    </div>

    <div class="col-lg-2 col-md-2">
      <app-select-default
        key="causes"
        title="Causas"
        [dropdownList]="filters.causes"
        [selectedItems]="['VANDALISMO']"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2">
      <app-select-default
        key="regionals"
        title="Regionais"
        [dropdownList]="data.value?.filters?.dropdown?.regionals"
        [selectedItems]="data.value?.filters?.selected?.regionals"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2">
      <app-select-default
        key="directors"
        title="Diretorias"
        [dropdownList]="data.value?.filters?.dropdown?.directors"
        [selectedItems]="data.value?.filters?.selected?.directors"
        [itemsShowLimit]="1"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2">
      <app-select-default
        key="validations"
        title="Validações"
        [dropdownList]="filters.validations"
        [selectedItems]="selectedValidations.value"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2"></div>

    <div class="col-lg-2 col-md-2"></div>
  </div> <!-- selects filters -->

  <div class="row cards-row d-flex justify-content-end" style="margin-bottom: 15px;">
    <div class="col-lg-3 col-md-3 col-xxl-2">
      <app-request-wrapper
        [class]="requestWrapperConfig.cardStyle"
        [isLoading]="loading"
        [isError]="error"
      >
        <app-card-default
          [icon]="icons.headset"
          [cardTitle]="'Número de atividades'"
          [cardValue]="data.value.totalTickets">
        </app-card-default>
      </app-request-wrapper>
    </div>

    <div class="col-lg-3 col-md-3 col-xxl-2">
      <app-request-wrapper
        [class]="requestWrapperConfig.cardStyle"
        [isLoading]="loading"
        [isError]="error"
      >
        <app-card-default
          [icon]="icons.image"
          [cardTitle]="'Número de imagens'"
          [cardValue]="data.value?.totalImages">
        </app-card-default>
      </app-request-wrapper>
    </div>
  </div>

  <div class="legends-container">
    <div 
      *ngFor="let row of getLegendRows(this.data.value.legends)"
      class="legend-row"
    >
      <div 
        *ngFor="let legend of row" class="legend-item"
        (click)="setValidations(legend.description)"
        [class.active]="isActive(legend.description)"
      >
        <app-verification-span
          [data]="mappedValidation(legend.value)"
        >
        </app-verification-span>
        <span class="legend-description">{{ legend.description }}</span>
      </div>
    </div>
  </div>

  <app-request-wrapper
    [class]="requestWrapperConfig.table"
    [isLoading]="loading"
    [isError]="error"
  >
    <div class="div-container">
      <table class="image-verification-table">
        <tr class="image-verification-row">
          <th 
            class="image-verification-header "
            *ngFor="let value of data.value.headers"
          >
            {{ value }}
          </th>
        </tr>

        <tr class="image-verification-row" *ngFor="let imageValidation of data.value.body">
          <td
            class="image-verification-body image-verification-body-column"
            (click)="openTicketPopUp(imageValidation.ticketId)"
          >
            {{ imageValidation.ticketId }}
          </td>
          <td class="image-verification-body">
            <app-verification-span
              [data]="mappedValidationCause(imageValidation.imageValidation)"
            >
            </app-verification-span>
          </td>
          <td class="image-verification-body">
            {{ imageValidation.quantity }}
          </td>    
          <td
            class="image-verification-body"
            *ngFor="let values of imageValidation.validations"
          >
            <app-verification-span
              *ngFor="let value of values"
              [data]="mappedValidation(value)"
            >
            </app-verification-span>
          </td>
        </tr>
      </table>
    </div>
  </app-request-wrapper>
  
</div>
