<div class="smart-table-container">

  <div class="actions-wrapper">

    <!-- Botões de Importação e Exportação -->
    <div *ngIf="importable || exportable" class="buttons-container">
      <button *ngIf="importable" [disabled]="!showImportButton" class="btn-import" (click)="onImport()">Import</button>
      <button *ngIf="exportable" [disabled]="!showExportButton" class="btn-export" (click)="onExport()">Export</button>
    </div>

    <!-- Exibição de Spans (cards) -->
    <div *ngIf="cards != null && cards?.length > 0" class="action-items-container">
      <span
        *ngFor="let item of cards"
        class="action-item"
        [attr.data-value]="item.value"
      >
        <fa-icon [icon]="item.icon" class="action-icon"></fa-icon>
        <span class="description">{{ item.description }}: </span>
        <span class="value">{{ item.value }}</span>
      </span>
    </div>

    <!-- Linha de Botões -->
    <div *ngIf="searchable" class="search-container">
      <input
        type="text"
        class="search-input"
        [(ngModel)]="searchQuery"
        placeholder="Digite para buscar..."
        (input)="onSearchInputChange()"
      />
    </div>
  </div>

  <!-- Tabela -->
  <div class="table-wrapper" [ngStyle]="{ 'max-height': 'calc(' + maxHeight + ' * 2rem)' }">
    <table *ngIf="(preview ? paginatedData : data)?.length > 0; else noDataMessage">
      <thead>
        <tr>
          <th 
            *ngFor="let value of data[0]?.values" 
            [matTooltip]="value.column"
            (click)="onSort(value.field)" 
            [ngClass]="{
              'sortable': sortable && value.sortable,
              'highlighted': isHeaderhighlighted(value.column),
              'active': isCurrentSortField(value.field)
            }"
          >
            <div class="header-content">
              <span class="column-text">{{ value.column }}</span>
              <!-- Indicador de ordenação -->
              <span *ngIf="value.sortable" class="sort-icon-container">
                <i 
                  class="sort-icon" 
                  [ngClass]="{
                    'asc': getSortDirection(value.field) === 'ASC',
                    'desc': getSortDirection(value.field) === 'DESC'
                  }"
                ></i>
              </span>
            </div>
          </th>
        </tr>
      </thead>
      
      <tbody>
        <tr *ngFor="let row of (preview ? paginatedData : data); let rowIndex = index; trackBy: trackByRow">
          <td *ngFor="let cell of row.values; let colIndex = index; trackBy: trackByCell"
              [attr.data-cell-id]="cell.column + '-' + cell.value"
              [matTooltip]="cell?.messages"
              [ngClass]="{
                'cell-modified': isModified(cell, row.id),
                'cell-invalid': !validateCell(cell, rowIndex),
                'highlight-search': cell.isHighlighted
              }">
            
            <!-- Exibe bolinhas se for um indicador -->
            <div *ngIf="cell.indicator; else normalCell"
              class="indicator-container"
              (click)="onCellClick($event, cell, row.id)">
              <span *ngFor="let indicator of cell.value"
                class="dot"
                [ngClass]="indicator"
                [matTooltip]="indicator">
              </span>
            </div>
      
            <!-- Exibe o input normal se não for indicador -->
            <ng-template #normalCell>
              <input
                [value]="getModified(cell, row.id)"
                (input)="onInputUpdate($event, cell, row.id)"
                [readonly]="!canEditCell(cell)" 
                [placeholder]="canEditCell(cell) ? 'Digite aqui...' : ''"
                [ngClass]="{
                  'cell-editable': canEditCell(cell),
                  'cell-readonly': !canEditCell(cell),
                  'cell-clikable':  cell.clickable
                }"
                (click)="onCellClick($event, cell, row.id)"
              />
            </ng-template>
          </td>
        </tr>
      </tbody>      
    </table>

    <!-- Mensagem condicional -->
    <ng-template #noDataMessage>
      <div class="no-data-message">
        <p>Nenhum dado disponível para exibição.</p>
      </div>
    </ng-template>
  </div>

  <!-- Indicador de Erros -->
  <div class="error-indicator" *ngIf="!(invalidMap.size === 0)">

    <!-- Botões de navegação -->
    <button
      type="button"
      class="btn-nav"
      (click)="navigateErrors('prev')"
      [disabled]="currentErrorIndex === 0"
      aria-label="Erro Anterior"
    >
      &#8592; <!-- Seta para esquerda -->
    </button>

    <span>{{ invalidMap.size }} erro(s) encontrado(s)</span>

    <button
      type="button"
      class="btn-nav"
      (click)="navigateErrors('next')"
      [disabled]="currentErrorIndex > 0"
      aria-label="Próximo Erro"
    >
      &#8594; <!-- Seta para esquerda -->
    </button>
  </div>
  
  <!-- Controles de paginação e botão de envio -->
  <div class="controls-wrapper" *ngIf="this.data.length > 0">
    <!-- Controles de paginação -->
    
    <div *ngIf="paginable" class="controls-container">
      <button 
        class="btn-prev"
        (click)="goToPage(currentPage - 1)" 
        [disabled]="currentPage === 1" 
        aria-label="Página Anterior">
        Anterior
      </button>
    
      <label for="page-input">
        Página <input 
          id="page-input"
          type="number" 
          [min]="1" 
          [max]="totalPages" 
          [value]="currentPage"
          (change)="goToPage($event.target.value)" 
          aria-label="Ir para página" 
        /> de {{ totalPages }}
      </label>      
    
      <button 
        class="btn-next"
        (click)="goToPage(currentPage + 1)" 
        [disabled]="currentPage === totalPages" 
        aria-label="Próxima Página">
        Próximo
      </button>
    </div>
  
    <!-- Botão de enviar -->
    <div *ngIf="submitable" class="submit-container">
      <button 
        class="btn-submit"
        (click)="submitChanges()" 
        [disabled]="!(invalidMap.size === 0)"
        aria-label="Enviar Alterações">
        Enviar Alterações
      </button>
    </div>
  </div>  
</div>
