<!-- Table Section -->
<div 
  *ngIf="isTable" 
  class="container-table-suggestion"
  (mousedown)="onMouseDown($event)"
  (document:mousemove)="onMouseMove($event)"
  (document:mouseup)="onMouseUp()"
  [style.top.px]="position.y"
  [style.left.px]="position.x"
  [ngClass]="{'minimized': isMinimized}"
>
  <div class="container-button">
    <button (click)="togglePreview()" class="btn-close-default" [ngClass]="{ 'btn-minimized': isMinimized }">
      <span *ngIf="isMinimized">
        <i class="fa fa-times"></i>
      </span>  
      <span *ngIf="!isMinimized">
        <i class="fa fa-arrows-alt"></i>
      </span>      
    </button>    
  </div>
  <table *ngIf="isTable && isMinimized" class="table-suggestion">
    <thead class="table-suggestion-header">
      <tr class="table-thead-tr">
        <th class="table-cell">id</th>
        <th class="table-cell">regional</th>
        <th class="table-cell">score</th>
      </tr>
    </thead>
    <tbody class="table-suggestion-body">
      <tr
        *ngFor="let suggestion of suggestionPeads"
        class="table-body-tr"
        (click)="peadSuggestionClick(suggestion)"
      >
        <td class="table-cell">{{suggestion.suggestionId}}</td>
        <td class="table-cell">{{suggestion.regional}}</td>
        <td class="table-cell">{{suggestion.score}}</td>
      </tr>

      <div *ngIf="isLoading" class="table-suggestion-loading-screen">
        <div class="loading-animation">
          <div class="spinner-container">        
            <div class="spinner"></div>
          </div>      
        </div>
      </div>  
    </tbody>
  </table>
</div>