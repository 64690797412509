export enum RequestType {
  LoginSuccess = 'LOGIN_SUCCESS',
  CreatePasswordSuccess = 'CREATE_PASSWORD_SUCCESS',
  ForgetPasswordSendMessageSuccess = 'SEND_MESSAGE_SUCCESS',
  ResetPasswordSuccess = 'RESET_PASSWORD_SUCCESS',
  LoginInvalidCrendentials = 'LOGIN_INVALID_CREDENTIALS',
  CreatePasswordInvalid = 'CREATE_PASSWORD_INVALID',
  RouteCreatedSuccess="ROUTE_CREATED_SUCCESS",
  RouteAlreadyExists="ROUTE_ALREADY_EXISTS",
  UserNotFound = 'USER_NOT_FOUND',
  InternalError = 'INTERNAL_ERROR',
  ResetPasswordInvalidCode = 'RESET_PASSWORD_INVALID_CODE',
}

export enum RequestStyle {
  SUCCESS = 'success-bar-container',
  FAILED = 'failure-bar-container'
}

export enum RequestStatus {
  OK = 200,
  CREATED = 201,
  ALREADY_REPORTED = 208,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500
}

export interface Request {
  status: number,
  message: string
}

export interface RequestResult extends Request {
  isError: boolean,
  styleClass?: string
};

export type RequestMessageMap = Partial<Record<RequestType, RequestResult>>

export type MessageStatusResponse = Record<number, string>;

export const mountRequestResult = ({ message, request, expectedStatus }: { message?: string, request: Request, expectedStatus: RequestStatus }) => {
  return {
    status: request.status,
    message: message ? message : request.message,
    isError: !(expectedStatus === request.status),
    styleClass: expectedStatus === request.status ? RequestStyle.SUCCESS : RequestStyle.FAILED
  }
}