import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { INotification, IAlert } from 'src/app/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit {
  imgUser:string = '/assets/images/avatars/userProfile.png';
  iconSuport:string = '/assets/icons/suport-icon.png';
  email: string = '';
  fullname: string = '';
  company: string = '';
  role: string = '';    
  
  constructor(
    private authService: AuthService,
    private router:Router
  ) {
    const currentUser = authService.getCurrentUser();

    this.email = currentUser.email;
    this.fullname = currentUser.fullName;
    this.role = currentUser.role;
  }

  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  ngOnInit(): void {
  }

  openTicket():void
  {
    this.router.navigate(['/suporte']);
  }

}
