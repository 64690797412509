import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { mountRequestResult, RequestResult, RequestStyle } from 'src/app/interfaces/RequestContext';
import { BehaviorSubject } from 'rxjs';
import { ImportResponse, PublisherService } from 'src/app/services/publisher.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { IpAddressService } from 'src/app/services/ip-address.service';

@Component({
  selector: 'app-import-users-modal',
  templateUrl: './import-users-modal.component.html',
  styleUrls: ['./import-users-modal.component.scss']
})
export class ImportUsersModalComponent {

  loading = false;
  response: ImportResponse | null = null;
  selectedFile: File | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    private snackBar: MatSnackBar,
    private publisherService: PublisherService,
    private authService: AuthService,
    private ipAddressService: IpAddressService
  ) {}

  // Handle file selection
  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  downloadTemplate(): void {
    // TODO: lógica para identificar a empresa e pegar um modelo de uma empresa especifica;
    this.loading = true;

    const { fullName: fullname, username, email, company: { code: companyCode } } = this.authService.getCurrentUser();
    const { ipAddress: requesterIp } = this.ipAddressService.getStoredIpAddress();

    const input = {
      fullname,
      username,
      email,
      companyCode,
      requesterIp
    }

    this.publisherService.sendModel(input).subscribe(
      (response) => {
        this.loading = false;
        const requestResult: RequestResult = mountRequestResult({
          request: { ...response, status: 200 },
          message: response.message,
          expectedStatus: 200
        });
        this.openPopUpResponse(requestResult);
      },
      (error) => {
        this.loading = false;
        const requestResult: RequestResult = mountRequestResult({
          request: { ...error },
          message: error.error.message,
          expectedStatus: 200
        });
        this.openPopUpResponse(requestResult);
      }
    )
  }

  importUsers() {
    if (this.selectedFile) {
      this.loading = true;
      const companyCode = this.authService.getCompanyCode();
      const input = {
        file: this.selectedFile,
        companyCode
      }
      this.publisherService.importDocument(input).subscribe(
        (data: ImportResponse) => {
          this.response = data;
          this.loading = false;
      
          const requestResult: RequestResult = {
            message: 'A lista de usuários foi importada com sucesso.',
            isError: false,
            styleClass: RequestStyle.SUCCESS,
            status: 200
          };
          this.openPopUpResponse(requestResult);
        },
        (error) => {
          console.error('Erro ao importar usuários:', error);
          this.loading = false;
      
          let errorMessage = 'Ocorreu um erro ao importar os usuários.';
      
          // Verifica se o servidor retornou uma mensagem de erro específica
          if (error.status === 0) {
            errorMessage = 'Erro de conexão. Verifique sua conexão com a internet e tente novamente.';
          } else if (error.status >= 500) {
            errorMessage = 'Erro interno do servidor. Por favor, tente novamente mais tarde.';
          } else if (error.status >= 400) {
            errorMessage = 'Erro ao processar o pedido. Verifique os dados e tente novamente.';
          }
      
          const requestResult: RequestResult = {
            message: errorMessage,
            isError: true,
            styleClass: RequestStyle.FAILED,
            status: error.status
          };
          this.openPopUpResponse(requestResult);
        }
      );
      
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
