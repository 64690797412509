export interface ResponseValidator {
  isValid: boolean,
  errorMessage?: string
}

export class Validators {

  static notEmpty(value: any): ResponseValidator {
    const isValid = value !== null && value !== undefined && value.toString().trim() !== '';
    return {
      isValid,
      errorMessage: isValid ? undefined : 'O campo não pode estar vazio.',
    };
  }

  static isNumber(value: any): ResponseValidator {
    const isValid = value === null || value === undefined || !isNaN(value);
    return {
      isValid,
      errorMessage: isValid ? undefined : 'O valor deve ser um número.',
    };
  }

  static isValidDate(value: any): ResponseValidator {
    if (!value) {
      return { isValid: true };
    }
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    const isValid = regex.test(value);
    return {
      isValid,
      errorMessage: isValid ? undefined : 'A data deve estar no formato DD/MM/AAAA.',
    };
  }

  static isInOptions(value: any, options: string[]): ResponseValidator {
    //const isValid = value === null || value === undefined || options.includes(value) || value === '';
    const isValid = !value || options.includes(value);
    return {
      isValid,
      errorMessage: isValid ? undefined : `O valor deve ser uma das opções: ${options.join(', ')}.`,
    };
  }
}
