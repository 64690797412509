import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface SupportRequest {
  userId: number;
  supportSubjectId: number;
  description: string;
  file?: File;
  reportedDate: string;
  company: number;
}

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private readonly supportBaseUrl = `${environment.baseUrl}/vandalism/support`;
  private readonly subjectUrl = `${this.supportBaseUrl}/subjects`;

  constructor(private http: HttpClient) {}

  sendSupportRequest(request: SupportRequest): Observable<{ message: string }> {
    const formData = this.createSupportFormData(request);
    return this.http.post<{ message: string }>(this.supportBaseUrl, formData);
  }

  getSubjects(): Observable<{ id: number; supportSubject: string }[]> {
    return this.http.get<{ id: number; supportSubject: string }[]>(this.subjectUrl);
  }

  publishSubject(supportSubject: string): Observable<any> {
    const url = `${environment.publisherUrl}/publisher/support-sender`;
    const body = new URLSearchParams({ subject: supportSubject });
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post<any>(url, body.toString(), { headers });
  }

  private createSupportFormData(request: SupportRequest): FormData {
    const formData = new FormData();
    formData.append('userId', request.userId.toString());
    formData.append('supportSubjectId', request.supportSubjectId.toString());
    formData.append('description', request.description);
    formData.append('reportedDate', request.reportedDate);
    formData.append('company', request.company.toString());

    if (request.file) {
      formData.append('file', request.file, request.file.name);
    }

    return formData;
  }
}
