import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private baseUrl = `${environment.baseUrl}/vandalism/support`;
  private subjectUrl = `${this.baseUrl}/subjects`;
  private publisherUrl = `${environment.publisherUrl}/publisher`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  getPendingTickets(userId: number, page: number, size: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/all-supports-user`, {
      params: {
        status: 'pending',
        userId: userId.toString(),
        page: page.toString(),
        size: size.toString(),
      },
    });
  }

  getCompletedTickets(userId: number, page: number, size: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/all-supports-user`, {
      params: {
        status: 'replied',
        userId: userId.toString(),
        page: page.toString(),
        size: size.toString(),
      },
    });
  }

  populateTicketSubjects(tickets: any[]): void {
    tickets.forEach(ticket => {
      if (ticket.supportIDSubject) {
        this.getSubjectById(ticket.supportIDSubject).subscribe({
          next: (response) => {
            ticket.supportSubject = response.supportSubject;
          },
          error: () => {
            ticket.supportSubject = 'Assunto não encontrado';
          }
        });
      }
    });
  }

  getTicketsByUrl(url: string): Observable<any[]> {
    return this.http.get<any[]>(url);
  }

  updateTicket(ticketId: number, newSupportId: number): Observable<any> {
    const url = `${this.baseUrl}/support-id/${ticketId}?newSupportId=${newSupportId}`;
    return this.http.put(url, {});
  }

  getSubjectById(id: number): Observable<any> {
    return this.http.get<any>(`${this.subjectUrl}/${id}`);
  }

  replyToTicket(ticketId: number, formData: FormData): Observable<any> {
    return this.http.put(`${this.baseUrl}/respond/${ticketId}`, formData);
  }

  publishTicket(ticket: any, email: string, subject: string): Observable<any> {
    const formData = new FormData();
    formData.append('recipientEmail', email);
    formData.append('subject', subject);
    formData.append('deadline', ticket.deadline);
    return this.http.post(`${this.publisherUrl}/assign-ticket`, formData);
  }

  publishRepliedTicket(ticket: any, userId: number, subject: string): Observable<any> {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('userId', userId.toString());
    return this.http.post(`${this.publisherUrl}/support-reply`, formData, { responseType: 'text' });
  }

  downloadTicketFile(ticketId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.baseUrl}/download/${ticketId}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
