import { Injectable } from '@angular/core';
import { SmartTableRow } from 'src/app/components/smart-table/smart-table.component';
import { ExcelTransformStrategy } from 'src/app/interfaces';
import { Validators } from 'src/app/interfaces/Validators';

@Injectable()
export class TechnicianImageTransformStrategy implements ExcelTransformStrategy {

  // Configuração de campos
  private readonly fieldConfig = [
    {
      column: 'ID DA ATIVIDADE',
      key: 'ticketId',
      field: 'ticketId',
      defaultValue: '',
      indicator: false,
      requiredPermissionToUpdate: null,
      sortable: true,
      clickable: true
    },
    {
      column: 'ID DO INCIDENTE',
      key: 'incidentId',
      field: 'incidentId',
      defaultValue: '',
      indicator: false,
      requiredPermissionToUpdate: null,
      sortable: true
    },
    {
      column: 'ID DO TÉCNICO',
      key: 'technicianId',
      field: 'technicianId',
      defaultValue: '',
      indicator: false,
      requiredPermissionToUpdate: null,
      readonly: true,
      sortable: false
    },
    {
      column: 'NÚMERO DA IMAGEM',
      key: 'imageIndex',
      field: 'imageIndex',
      validators: [Validators.isNumber],
      defaultValue: '',
      indicator: false,
      requiredPermissionToUpdate: null,
      sortable: true,
      clickable: false
    },
    {
      column: 'EMPRESA',
      key: 'company',
      field: 'company',
      defaultValue: '',
      indicator: false,
      requiredPermissionToUpdate: null,
      sortable: true,
      clickable: false,
      tooltip: true
    },
    {
      column: 'VALIDAÇÕES',
      key: 'validations',
      field: 'validations',
      defaultValue: '',
      indicator: true,
      transform: this.parseValidationindicator,
      requiredPermissionToUpdate: null,
      sortable: false,
      clickable: false
    }
  ];

  // Método para obter validadores
  validators() {
    return this.fieldConfig.reduce((acc, field) => {
      acc[field.column] = field?.validators || [];
      return acc;
    }, {});
  }

  // Método principal de transformação
  transform(data: any[]): SmartTableRow[] {
    return data.reduce((result: SmartTableRow[], row, rowIndex) => {
      const id = row['id'];
      const values = this.fieldConfig.map((field, colIndex) => ({
        cellId: rowIndex * 100 + colIndex + 1,
        column: field.column,
        field: field.field,
        value: this.transformFieldValue(row[field.key], field),
        requiredPermissionToUpdate: field.requiredPermissionToUpdate,
        indicator: field.indicator,
        readonly: field.readonly,
        sortable: field.sortable,
        clickable : field.clickable,
        tooltip: field?.tooltip
      }));

      result.push({ id, values });

      return result;
    }, []);
  }

  private transformFieldValue(value: any, field: any): any {
    if (field.transform) {
      return field?.transform(value);
    }
    return value ?? field.defaultValue;
  }

    // Método auxiliar para parse de datas
    private parseValidationindicator(value: string): string[] {
      if (value !== null && typeof value === 'string') {
        return value.split(',');
      } 
      return [];
    }
}
