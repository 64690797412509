import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// Auth pages
import { LoginComponent } from './pages/auth/login/login.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';

// Vandalismo Pages
import { DetalhamentoComponent } from './pages/vandalismo/detalhamento/detalhamento.component';
import { AlertaChamadosComponent } from './pages/vandalismo/alerta-chamados/alerta-chamados.component';
import { AnaliticoComponent } from './pages/vandalismo/analitico/analitico.component';

// Common Pages
import { HomeComponent } from './pages/common/home/home.component';
import { NotFoundComponent } from './pages/common/not-found/not-found.component';

import { Permission, Role } from './interfaces';
import { PermissionGuard } from './guards/permission.guard';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { UserManagementComponent } from './pages/user/user-management/user-management.component';
import { PreferencesComponent } from './pages/user/preferences/preferences.component';
import { CreatePasswordComponent } from './pages/auth/create-password/create-password.component';
import { RotaComponent } from './pages/vandalismo/rota/rota.component';
import { EfetividadeComponent } from './pages/vandalismo/efetividade/efetividade.component';
import { AnalyticalFullscreenProxyComponent } from './maps/analytical-fullscreen-map/components/analytical-fullscreen-proxy.component';
import { VerificacaoImagesComponent } from './pages/images/verificacao-images/verificacao-images.component';
import { MapaOperacoesProxyComponent } from './pages/vandalismo/mapa-operacoes/components/mapa-operacoes-proxy.component';
import { MapaInventarioProxyComponent } from './pages/vandalismo/mapa-inventario/components/mapa-inventario-proxy.component';
import { AnaliticoImagesComponent } from './pages/images/analitico-images/analitico-images.component';
import { AccessControlComponent } from './pages/user/access-control/access-control.component';
import { ConfirmationPasswordComponent } from './pages/auth/confirmation-password/confirmation-password.component';
import { AccessControlDirectoryComponent } from './pages/user/access-control-directory/access-control-directory.component';
import { SugestaoComponent } from './pages/vandalismo/sugestao/sugestao.component';
import { UserSupportComponent } from './pages/user/user-support/user-support.component'
import { SupportTicketsComponent } from './pages/user/support-tickets/support-tickets.component';
import { PendingTicketsComponent } from './pages/user/user-support/pending-tickets/pending-tickets.component';
import { CompleteTicketsComponent } from './pages/user/user-support/completed-tickets/completed-tickets.component';
import { RecurrenceComponent } from './pages/user/recurrence/recurrence.component';

const routes: Routes = [
  {
    path: '',
    component: PagesLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'create-password',
        component: CreatePasswordComponent
      },
      {
        path: 'confirmation/:token',
        component: ConfirmationPasswordComponent,
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
    ]
  },
  {
    path: 'area-administrador',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ADMINISTRATOR] },
    children: [
      {
        path: 'usuarios',
        component: UserManagementComponent,
      },
      {
        path: 'preferencias',
        component: PreferencesComponent,
      },
      {
        path: 'controle-acesso',
        component: AccessControlComponent
      },
      {
        path: 'controle-acesso/diretorias',
        component: AccessControlDirectoryComponent
      },
      {
        path: 'support',
        component: SupportTicketsComponent
      },
      {
        path: '',
        redirectTo: 'usuarios',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'vandalismo',
    component: BaseLayoutComponent,
    canActivate: [PermissionGuard],
    data: { permission: Permission.VANDALISMO },
    children: [      
      {
        path: 'detalhamento',
        component: DetalhamentoComponent
      },
      {
        path: 'alerta',
        component: AlertaChamadosComponent
      },      
      { 
        path: 'rota',
        component: RotaComponent
      },
      { 
        path: 'analitico',
        component: AnaliticoComponent,
      },
      {
        path: 'efetividade',
        component: EfetividadeComponent,
      },
      {
      
        path: 'sugestao',
        component: SugestaoComponent,
      },
      {
        path: 'mapa',
        component: AnalyticalFullscreenProxyComponent,
      },
      {
        path: 'mapa-operacoes',
        component: MapaOperacoesProxyComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permission.OPERATION }
      },
      {
        path: 'mapa-preventivo',
        component: MapaInventarioProxyComponent,
        // canActivate: [PermissionGuard],
        // data: { permission: Permission.INVENTORY }
      },
      {
        path: '',
        redirectTo: 'analitico',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'image',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'verification',
        component: VerificacaoImagesComponent
      },
      {
        path: 'analytical',
        component: AnaliticoImagesComponent
      }
    ]
  },
  {
    path: 'suporte',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: UserSupportComponent
      },
      {
        path: 'pendentes',
        component: PendingTicketsComponent
      },
      {
        path: 'respondidos',
        component: CompleteTicketsComponent
      }
    ]
  },
  { 
    path: 'geral',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'reincidencia',
        component: RecurrenceComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permission.RECURRENCE_VIEW }
      },
      {
        path: '',
        redirectTo: 'reincidencia',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
