export const mapConfigStyle = [ 
  {
    featureType: 'all',
    stylers: [
      { saturation: -60 }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      { hue: '#00ffee' },
      { saturation: 100 }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#523735' }
    ]
  },
];

export const mapConfig = 
{
  zoom: 4,
  zoomControl: true,
  streetViewControl: false,
  fullscreenControl: false,    
  // iconFullScreen: '/assets/maps/fullscreen_desativado.svg',
  latitude: -23.5505199, 
  longitude: -46.6333094 
};