import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/services/support-tickets.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-support-tickets',
  templateUrl: './support-tickets.component.html',
  styleUrls: ['./support-tickets.component.sass'],
})
export class SupportTicketsComponent implements OnInit {
  tickets: any[] = [];
  teamMembers: any[] = [];
  totalTickets = 0;
  totalPages = 0;
  currentPage = 1;
  pageSize = 9;
  selectedTicket: any = null;
  subjectSelected: string = '';
  response: string = '';
  displayingResponseForm = false;
  showResolvedTickets = false;

  constructor(
    private supportService: SupportService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadTickets();
  }

  loadTickets(): void {
    const user = this.authService.getCurrentUser();
    if (!user) return console.error('Usuário não autenticado.');
  
    const status = this.showResolvedTickets ? 'replied' : 'pending';
    const permission = this.hasPermission(7) ? 'admin' : '';
  
    const url = `${environment.baseUrl}/vandalism/support/all-supports?userId=${user.id}&status=${status}&permission=${permission}&page=${this.currentPage}&size=${this.pageSize}`;
  
    this.supportService.getTicketsByUrl(url).subscribe({
      next: (response: any) => {
        this.tickets = response.supports || [];
        this.totalTickets = response.totalElements || 0;
        this.totalPages = response.totalPages || 0;
        this.teamMembers = response.users || [];
      },
      error: (error) => console.error('Erro ao carregar tickets:', error),
    });
  }
  

  getUserName(userId: number): string {
    const user = this.teamMembers.find((u) => u.id === userId);
    return user ? user.fullName : 'Usuário desconhecido';
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadTickets();
    }
  }

  onPageChange(page: number): void {
    this.goToPage(page);
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }

  toggleResolvedTickets(): void {
    this.showResolvedTickets = !this.showResolvedTickets;
    this.currentPage = 1; 
    this.loadTickets();
  }

  openModal(ticket: any): void {
    this.selectedTicket = ticket;
    if (ticket.supportIDSubject) {
      this.supportService.getSubjectById(ticket.supportIDSubject).subscribe({
        next: (response) => (this.subjectSelected = response.supportSubject),
        error: () => (this.subjectSelected = 'Erro ao carregar assunto'),
      });
    }
  }

  closeModal(): void {
    this.selectedTicket = null;
  }

  showReplyForm(): void {
    this.displayingResponseForm = true;
  }
  
  cancelReply(): void {
    this.displayingResponseForm = false;
    this.response = '';
  }
  
  updateTicket(ticket: any): void {
    if (!ticket || !ticket.id) return alert('Ticket inválido!');
    const membroId = +(document.getElementById('teamMembers') as HTMLSelectElement).value;
    const membro = this.teamMembers.find((m) => m.id === membroId);
  
    if (!membro) return alert('Membro da equipe não encontrado!');
  
    const url = `${environment.baseUrl}/vandalism/support/support-id/${ticket.id}?newSupportId=${membro.id}`;
    const subjectId = ticket.supportIDSubject;
  
    this.supportService.getSubjectById(subjectId).subscribe({
      next: (subjectResponse) => {
        const subjectName = subjectResponse.supportSubject;
  
        this.supportService.updateTicket(ticket.id, membro.id).subscribe({
          next: () => {
            this.loadTickets();
            this.closeModal();
            this.showSnackBar('Ticket atualizado com sucesso!', false);
  
            this.supportService.publishTicket(ticket, membro.email, subjectName).subscribe({
              next: () => console.log('Publisher enviado com sucesso'),
              error: () => console.error('Erro ao enviar ao publisher.'),
            });
          },
          error: () => this.showSnackBar('Erro ao atualizar o ticket. Tente novamente.', true),
        });
      },
      error: () => console.error('Erro ao obter o assunto do ticket'),
    });
  }

  sendReply(ticket: any): void {
    if (!this.response.trim()) {
      this.showSnackBar('Por favor, escreva uma resposta.', true);
      return; 
    }
    
    const user = this.authService.getCurrentUser();
    if (!user?.id) return alert('Usuário não autenticado.');
  
    const url = `${environment.baseUrl}/vandalism/support/respond/${ticket.id}`;
    const formData = new FormData();
    formData.append('answer', this.response);
    formData.append('responderId', user.id.toString());
  
    const subjectId = ticket.supportIDSubject;
  
    this.supportService.replyToTicket(ticket.id, formData).subscribe({
      next: () => {
        this.resetReplyForm();
        this.loadTickets();
        this.showSnackBar('Resposta enviada com sucesso!', false);
  
        this.supportService.getSubjectById(subjectId).subscribe({
          next: (subjectResponse) => {
            const subjectName = subjectResponse.supportSubject;
            this.supportService.publishRepliedTicket(ticket, ticket.userId, subjectName).subscribe({
              next: () => console.log('Resposta enviada para o publisher.'),
              error: () => this.showSnackBar('Erro ao enviar para o publisher. A resposta foi enviada com sucesso.', true),
            });
          },
          error: () => console.error('Erro ao obter o assunto do ticket'),
        });
      },
      error: () => this.showSnackBar('Erro ao enviar a resposta. Tente novamente.', true),
    });
  }

  resetReplyForm(): void {
    this.response = '';
    this.displayingResponseForm = false;
    this.closeModal();
  }

  downloadFile(ticketId: number): void {
    this.supportService.downloadTicketFile(ticketId).subscribe({
      next: (response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const fileName = contentDisposition?.match(/filename="(.+)"/)?.[1] || `ticket_${ticketId}`;

        const blobUrl = window.URL.createObjectURL(response.body!);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(blobUrl);

        this.showSnackBar(`Download iniciado: ${fileName}`, false);
      },
      error: () => this.showSnackBar('Erro ao fazer download do arquivo. Nenhum arquivo associado ao ticket.', true),
    });
  }

  showSnackBar(message: string, isError: boolean): void {
    this.snackBar.open(message, 'Fechar', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: isError ? 'failure-bar-container' : 'success-bar-container',
    });
  }

  hasPermission(permission: number): boolean {
    return this.authService.hasPermission(permission);
  }
}
