import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { VandalismService } from 'src/app/services/vandalism.service';
import { ZoomImagePopUpComponent } from '../zoom-image-pop-up/zoom-image-pop-up.component';
import { VandalismTicketPopup } from 'src/app/interfaces';
import { IDisplayItem } from 'src/app/interfaces/models/IDisplayItem'
import { Formatter } from 'src/app/utils/formatter.util';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { ActivityLogServiceService } from 'src/app/services/activity-log-service.service';
import { environment } from 'src/environments/environment';
import { PublisherService } from 'src/app/services/publisher.service';

@Component({
  selector: 'app-ticket-pop-up',
  templateUrl: './ticket-pop-up.component.html',
  styleUrls: []
})
export class TicketPopUpComponent implements OnInit {

  @Input() modalData: { ticketId: number, cause: string, guid: string  };
  @Input() isButtonLocations: boolean = false;

  message: string = "Ocorreu um erro ao tentar enviar o relatório por e-mail.";
  activityDetails: any[] = [];
  displayData: IDisplayItem[] = [];
  photos: string[] = [];
  sharpnessValidations = [null, null, null, null, null];
  disabledButton: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading = true;  
  error: boolean = false;
  imagemUrl;
  isLoading: boolean;
  isLocations: boolean = false;    
  location: VandalismTicketPopup.Location;
  isMapLoading: boolean = false;
  showInput: boolean = false;
  occurrenceReport: string | null = null;
  effectivenessLine:boolean = false;

  constructor(
    private vandalismService: VandalismService,    
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private logService: ActivityLogServiceService,
    private publisher: PublisherService
  ) {
    this.imagemUrl = environment.imagemUrl
  }
  
  ngOnInit() {  
    this.getTicketPopup(this.modalData.ticketId, this.modalData.guid);
  }

  mounthDisplayData(displayData: VandalismTicketPopup.DisplayDataPopup) {
    this.displayData = [
      { label: 'Id da Atividade', value: displayData.ticketId },
      { label: 'Task Id occurrence', value: displayData.taskIdIncident },      
      { label: 'Ticket no Sistema de Origem', value: displayData.ticketSystemOrigin },
      { label: 'Id do técnico', value: displayData.technicianId },
      { label: 'Empresa', value: displayData.company },
      { label: 'Endereço', value: displayData.address },
      { label: 'Bairro', value: displayData.district },
      { label: 'Data Reportada', value: displayData.reportedDate },
      { label: 'Data da rota', value: displayData.routeDate },
      { label: 'Operacional', value: displayData.operational },
      { label: 'Causa', value: displayData.cause },
      { label: 'Status', value: displayData.status },
      { label: 'Boletim de ocorrência', value: displayData.BO }
    ];

    if (displayData.isPead) {
      this.effectivenessLine = true;
      const peadsInfo = [
        { label: 'Quantidade', value: displayData.quantity },
        { label: 'Efetividade do Pead', value: this.treatEffectiveness(displayData.effectiveness) },
        { label: 'Dias antes da instalação', value: displayData.daysBefore },
        { label: 'Dias depois da instalação', value: displayData.daysAfter },
        { label: 'Tickets antes', value: displayData.ticketsBefore },
        { label: 'Tickets depois', value: displayData.ticketsAfter }
      ]
      
      this.displayData.push(...peadsInfo);
    }
  }

  treatEffectiveness(value: number) {
    return value === null ? 'N/A' : `${value}%`;
  }

  treatLegacyTicket(ticket: any, key: any) {
    const isLegacy = this.modalData.ticketId === 0
    return isLegacy ? '' :  ticket[key];
  }

  getFormatted(parts: string[]) {
    const treatParts = parts.filter(part => part !== null && part !== undefined);
    return treatParts.length > 1 ?  treatParts.join(' - ') : treatParts;
  }

  getPercentageValue(effectiveness: string | number): number {
    return typeof effectiveness === 'string' ? parseFloat(effectiveness) : effectiveness;
  }
  
  getEffectivenessClass(): string {
    const effectivenessData = this.displayData.find(data => data.label === 'Efetividade do Pead');
    
    // Retorna a classe padrão se os dados não estiverem carregados ainda
    if (!effectivenessData || effectivenessData.value == null) {
      return 'default-percentage';
    }
  
    const effectiveness = effectivenessData.value;
  
    if (effectiveness === 'N/A') {
      return 'na-percentage';
    }
  
    const percentage = this.getPercentageValue(effectiveness);
  
    if (percentage >= 70) {
      return 'high-percentage';
    } else if (percentage >= 40) {
      return 'medium-percentage';
    } else {
      return 'low-percentage';
    }
  }
  
  getPhotos(ticketId: number) {
    if (!ticketId) {
      this.loading = false;
      return;
    }

    this.loading = true;

    for (let i = 1; i <= 5 ; i++) {
      const endpoint = `${this.imagemUrl}/fotosClaro/${ticketId}-${i}.jpg`;
      this.photos.push(endpoint);
    }

    this.loading = false;
  }

  getTicketPopup(ticketId: number, guid: string) {
    if (ticketId === null || guid === null) {
      return;
    }
    
    this.vandalismService.getTicketPopup({ ticketId, guid })
      .subscribe((response: {
        ticket: VandalismTicketPopup.DisplayDataPopup,
        sharpnessValidations: boolean[],
        location: VandalismTicketPopup.Location,
        occurrence: string
      }) => {
        this.sharpnessValidations = response.sharpnessValidations;
        this.mounthDisplayData(response.ticket);       
        this.getPhotos(this.modalData.ticketId);
        this.location = response.location;     
        if (response.occurrence !== null) {
          this.occurrenceReport = response.occurrence;
        }   
      })
  }

  sendPhotoManualReport(imageIndex: number, validation: boolean) {
    const { ticketId } = this.modalData;

    const input = {
      ticketId,
      imageIndex: imageIndex + 1,
      validation
    }

    this.vandalismService.sendPhotoManualReport(input)
      .subscribe(
        (response) => {
          const { userId, companyCode } = this.authService.logCredentials();
          if (response && response.message) {     
            this.openPopUpResponseReport(response.message, true);
            this.logService.createImageFeedbackLog({ userId, companyCode });         
          }     
        },
        (error) => {
          this.openPopUpResponseReport("Erro ao processar solicitação", false)
        }
      )
  }

  toggleOccurrenceInput(): void {
    this.showInput = !this.showInput;
  }
  
  sendOccurrenceReport(): void {
    const { guid } = this.modalData;

    const input = {
      guid,
      occurrence: this.occurrenceReport
    }

    this.vandalismService.sendOccurrenceReport(input)
      .subscribe(
        (response) => {
          const { userId, companyCode } = this.authService.logCredentials();
          if (response && response.message) {     
            this.openPopUpResponseReport(response.message, true);
            this.getTicketPopup(this.modalData.ticketId, this.modalData.guid);
            this.logService.createRegisterOccurrenceReportLog({ userId, companyCode });         
          } 
        },
        (error) => {
          this.openPopUpResponseReport("Erro ao processar solicitação", false)
        }
      )

    this.showInput = false;
    this.occurrenceReport = null;
  }
  
  isAdmin() {
    return this.authService.isUserVirtsel();
  }

  treatDate(reportedDate: string, withHour: boolean) {
    return Formatter.formatReportedDate(reportedDate, withHour);
  }

  openGoogleMaps() {    
    this.isMapLoading = true;
  }

  onMapClosed() {
    this.isMapLoading = false;
  }

  openZoomImageModal(url: SafeUrl) {
    const modalRef = this.modalService.open(ZoomImagePopUpComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = { src: url };
  }

  async generateActivityReportPdf() {
    const ticketId  = String(this.modalData.ticketId);
    const { fullName, email } = this.authService.getCurrentUser();
    
    this.isLoading = true;
    try {      
      const response = await this.publisher.sendReportPdfEmail(ticketId, fullName, email).toPromise();
      
      if (response && response.message) {     
        this.openPopUpResponseReport(response.message, true)           
      }
    } catch (error) {      
      console.error('Erro ao enviar o relatório', error);      
      this.openPopUpResponseReport(this.message, false)           
    } finally {
      this.isLoading = false;
    }
  }

  openPopUpResponseReport(message:string, isSuccess: boolean) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: isSuccess ? 'success-bar-container' : 'failure-bar-container'
    }
    this.snackBar.open(message, closeButtonMessage, snackBarSettings);
  }

  closeModal() {
    this.activeModal.close();
  }
}
