import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ISuggestion } from 'src/app/interfaces/models/ISuggestion';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styles: [
  ]
})
export class GenericTableComponent implements OnInit {
  @Input() suggestionData: ISuggestion[] = []; 
  @Input() isLoading: boolean = false;  
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();  
  // @Output() suggestionClick = new EventEmitter<{ lat: number; lng: number }>();
  @Output() suggestionClick = new EventEmitter<ISuggestion>();
  @Output() buttonClicked = new EventEmitter<boolean>();

  timeoutId: any;
  activeRow: number | null = null;
  selectedRowColor: string = "#ECF0F1"
  
  constructor() { }
  
  ngOnInit(): void {       
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['effectivenessData']) {      
    }
  }  
  
  toggleDropdown(index: number) {    
    this.activeRow = this.activeRow === index ? null : index;
    this.selectedRowColor = "#c2c5c6"    
  }

  getPercentageValue(effectiveness: string | number): number {
    return typeof effectiveness === 'string' ? parseFloat(effectiveness) : effectiveness;
  }
  
  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.pageChange.emit(page);
    }
  }
   
  onPageChange(page: number) {    
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
        
    this.timeoutId = setTimeout(() => {
      this.goToPage(page);
    }, 1000);
  }
  
  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }
  
  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }
  
  goToPrevious10Pages() {
    this.currentPage = Math.max(1, this.currentPage - 10);
    this.pageChange.emit(this.currentPage);
  }  
  
  goToNext10Pages() {
    this.currentPage = Math.min(this.totalPages, this.currentPage + 10);
    this.pageChange.emit(this.currentPage);
  }  

  onSuggestionClick(item: ISuggestion) {
    this.suggestionClick.emit(item); // Emite o objeto completo
    this.buttonClicked.emit(true);
  }  
  
  get paginationRange() {
    const range = [];
    const delta = 2;

    const start = Math.max(2, this.currentPage - delta);
    const end = Math.min(this.totalPages - 1, this.currentPage + delta);

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  }

}
