import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { mountRequestResult, RequestResult, RequestStatus, RequestStyle } from 'src/app/interfaces/RequestContext';
import { AuthService } from 'src/app/services/auth.service';
import { caseValidator, specialCharacterValidator, numberValidator } from 'src/app/utils/inputValidators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  loading: boolean = true;
  submitted: boolean;
  error: boolean = false;

  newPassword: string;
  showNewPassword: boolean;

  confirmationPassword: string;
  showConfirmationPassword: boolean;

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required,  Validators.email],],
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        caseValidator,
        specialCharacterValidator,
        numberValidator,
        Validators.minLength(8),
      ])),
      confirmationPassword: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit() {
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  getEmail() {
    return this.form.controls.email.value
  }

  getNewPassword() {
    return this.form.controls.newPassword.value
  }

  toggleShowPassword() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmationPassword() {
    this.showConfirmationPassword = !this.showConfirmationPassword;
  }

  passwordsMatchValidator(control: AbstractControl) {
    const newPassword = control.get('newPassword').value;
    const confirmationPassword = control.get('confirmationPassword').value;
    if (newPassword !== confirmationPassword) {
      return true
    }
    return false
  }

  onSubmit(e) {
    e.preventDefault();

    this.submitted = true;

    if (this.form.invalid) {
      this.error = true;
      return;
    }

    this.loading = true;

    const payload = {
      email: this.getEmail(),
      newPassword: this.getNewPassword()
    }
  
    this.authService.forgetPassword(payload)
  .subscribe(
    (response) => {
      this.error = false;
      this.loading = false;
      const requestResult: RequestResult = mountRequestResult({ request: { status: 200, ...response }, expectedStatus: RequestStatus.OK });
      this.openPopUpResponse(requestResult);
      this.router.navigate(['/login']);
    },
    (error) => {
      this.error = true;
      this.loading = false;

      const errorMessage = error.status === RequestStatus.BAD_REQUEST
        ? 'Email ou senha estão incorretos. Por favor, tente novamente.'
        : 'Email ou senha estão incorretos. Por favor, tente novamente.';

      const snackBarSettings: MatSnackBarConfig = {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [RequestStyle.FAILED]
      };

      this.snackBar.open(errorMessage, 'Fechar', snackBarSettings);
    });
  }
}
