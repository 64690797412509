<div class="container">
  <!-- Card 1: Seleção da Tabela -->
  <div *ngIf="currentStep === 1" class="card-select shadow-lg">
    <div class="card-body">
      <h5 class="card-title text-center">Selecione a Tabela</h5>
      <select class="form-control mt-3" [(ngModel)]="selectedTable" (change)="onTableChange()">
        <option *ngFor="let table of tables" [value]="table.id">{{ table.descriptionTable }}</option>
      </select>

      <!-- Botão com sobreposição corrigida -->
      <div class="position-relative">
        <button class="generate-button" [disabled]="!selectedTable" (click)="validateAndProceed()">
          Próximo
        </button>

        <div *ngIf="!selectedTable" class="overlay-button"
          (click)="showSnackBar('Selecione uma tabela antes de continuar.', true)">
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentStep === 2" class="card-column shadow-lg">
    <div class="card-body">
      <!-- Título com o ícone de voltar -->
      <div class="button-arrow d-flex align-items-center justify-content-center position-relative">
        <button class="btn btn-link text-decoration-none position-absolute start-0 back-button"
          (click)="goToPreviousStep()" aria-label="Voltar">
          <i class="fa fa-arrow-left"></i>
        </button>
        <h5 class="card-title text-center m-0">Selecione as Colunas</h5>
      </div>

      <!-- Conteúdo do Card -->
      <div class="row mt-4">
        <!-- Lista de colunas disponíveis -->
        <div class="col-6">
          <h6>Colunas Disponíveis</h6>
          <ul class="list-group">
            <li *ngFor="let column of availableColumns"
              class="list-group-item d-flex justify-content-between align-items-center">
              {{ column.name }}
              <button class="btn btn-sm btn-primary" (click)="moveToSelected(column)">→</button>
            </li>
          </ul>
        </div>

        <!-- Espaçamento central -->
        <div class="col-2 text-center d-flex align-items-center justify-content-center">
          <span>&nbsp;</span>
        </div>

        <!-- Lista de colunas selecionadas -->
        <div class="col-5">
          <h6>Colunas Selecionadas</h6>
          <ul class="list-group">
            <li *ngFor="let column of selectedColumns"
              class="list-group-item d-flex justify-content-between align-items-center">
              {{ column.name }}
              <button class="btn btn-sm btn-secondary" (click)="moveToAvailable(column)">←</button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Botão Próximo -->
      <button class="generate-button" (click)="goToNextStep()" [disabled]="selectedColumns.length === 0">
        Próximo
      </button>
    </div>
  </div>

  <!-- Card 3: Seleção de Filtros -->
  <div *ngIf="currentStep === 3" class="card-filter shadow-lg">
    <div class="card-body">
      <!-- Título com o ícone de voltar -->
      <div class="button-arrow d-flex align-items-center justify-content-center position-relative">
        <button class="btn btn-link text-decoration-none position-absolute start-0 back-button"
          (click)="goToPreviousStep()" aria-label="Voltar">
          <i class="fa fa-arrow-left"></i>
        </button>
        <h5 class="card-title text-center m-0">Selecione os Filtros</h5>
      </div>

      <!-- Filtros por Data -->
      <div class="row mt-4">
        <div class="col-6">
          <label for="startDate">Data Inicial:</label>
          <input id="startDate" type="date" class="form-control" [(ngModel)]="startDate" />
        </div>
        <div class="col-6">
          <label for="endDate">Data Final:</label>
          <input id="endDate" type="date" class="form-control" [(ngModel)]="endDate" />
        </div>
      </div>

      <!-- Accordion para Filtros Dinâmicos -->
      <div *ngIf="isLoadingFilters" class="loading-overlay">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Carregando...</span>
        </div>
      </div>
      
      <div *ngIf="!isLoadingFilters">
        <!-- Conteúdo normal de filtros -->
        <div class="accordion-container mt-4" id="filtersAccordion">
          <div *ngFor="let column of selectedColumns" class="accordion-item">
            <div *ngIf="dynamicFilters[column.fieldRep] && dynamicFilters[column.fieldRep].length > 0" class="mt-3">
              <button class="accordion-btn" (click)="toggleAccordion(column.fieldRep)">
                <h6>{{ column.name }}</h6>
                <span>{{ isOpen[column.fieldRep] ? '↓' : '↑' }}</span>
              </button>
              <div class="accordion-content filter-container" [class.open]="isOpen[column.fieldRep]">
                <!-- Barra de Pesquisa -->
                <input
                type="text"
                class="form-control search-bar"
                placeholder="Pesquisar filtros..."
                [(ngModel)]="searchQueries[column.fieldRep]" />
                
                <div class="filter-list">
                  <div class="form-check" *ngFor="let value of filterValuesBySearch(column.fieldRep)">
                    <div class="container-filters">
                      <input class="form-check-input" type="checkbox" [(ngModel)]="value.selected" />
                      <label class="form-check-label">{{ value.value }}</label>
                    </div>
                  </div>
                </div>           
              </div>
            </div>
          </div>
        </div>
      </div>
            
      <!-- Botão para Gerar Tabela -->
      <!-- <button class="btn btn-primary w-100 mt-4" (click)="generateTable()">Gerar Tabela</button> -->
      <button class="generate-button" (click)="generateTable()">
        Gerar Tabela
      </button>
    </div>
  </div>

  <!-- Card 4: Exibição da Tabela Gerada -->
  <div *ngIf="currentStep === 4" class="card-table shadow-lg">
    <div class="card-body">
      <div class="button-arrow d-flex align-items-center justify-content-center position-relative">
        <button class="btn btn-link text-decoration-none position-absolute start-0 back-button"
          (click)="goToPreviousStep()" aria-label="Voltar">
          <i class="fa fa-arrow-left"></i>
        </button>
        <h5 class="card-title text-center m-0">Tabela Gerada</h5>
        <button class="btn btn-secondary position-absolute end-0 export-button" (click)="exportData()"
          aria-label="Exportar Dados">
          <i class="fa fa-download"></i> Exportar
        </button>
      </div>
      <!-- Tabela com rolagem -->
      <div class="table-scroll">
        <table class="table mt-3">
          <thead>
            <tr>
              <th *ngFor="let col of selectedColumns">{{ col.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of generatedData">
              <td *ngFor="let col of selectedColumns">
                <!-- Verifica se o valor da célula é uma data e formata -->
                <ng-container *ngIf="isDate(row[col.name]); else defaultValue">
                  {{ formatDate(row[col.name]) }}
                </ng-container>
                <ng-template #defaultValue>
                  {{ row[col.name] || 'N/A' }}
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex align-items-center justify-content-between button-page">
        <button class="btn btn-secondary" (click)="changePage('previous')"
          [disabled]="currentPage === 0">Anterior</button>
        <span class="d-flex align-items-center">
          Página
          <input type="number" [(ngModel)]="pageInput" (change)="jumpToPage()"
            class="form-control mx-2 text-center page-input" [min]="1" [max]="totalPages" [value]="currentPage + 1" />
          de {{ totalPages }}
        </span>
        <button class="btn btn-secondary" (click)="changePage('next')"
          [disabled]="currentPage === totalPages - 1">Próximo</button>
      </div>
    </div>
  </div>
</div>