import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { IToken, ILogin, Auth, Role, Permission } from '../interfaces';
import { IForgetPassword } from '../interfaces/models/IForgetPassword';
import { IResetPassword } from '../interfaces/models/IResetPassword';
import { ActivityLogServiceService } from './activity-log-service.service';
import { StorageService } from './storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  readonly baseUrl: string;
  readonly publisherUrl: string;
  private currentUserSubject: BehaviorSubject<Auth | null>;
  public currentUser: Observable<Auth | null>;
  private readonly STORAGE_KEY = 'current_user';
  private readonly ENCRYPTION_KEY = environment.encryptionKey;

  constructor(
    private http: HttpClient,
    private router: Router,
    private logService: ActivityLogServiceService,
    private storageService: StorageService
  ) {
    const storedUser = this.storageService.loadEncrypted(this.STORAGE_KEY, this.ENCRYPTION_KEY);
    this.currentUserSubject = new BehaviorSubject<Auth | null>(storedUser);
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = environment.authUrl;
    this.publisherUrl = environment.publisherUrl;
  }

  public login(payload: ILogin): Observable<{ data: Auth }> {
    return this.http
      .post<{ data: Auth }>(`${this.baseUrl}/auth/signin`, payload, httpOptions)
      .pipe(
        tap(({ data }) => {
          this.storageService.saveEncrypted(this.STORAGE_KEY, data, this.ENCRYPTION_KEY);
          this.currentUserSubject.next(data);
          const { userId, companyCode } = this.logCredentials();
          this.logService.createLoginLog({ userId, companyCode });
        })
    );
  }

  refreshToken(): Observable<IToken> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.logout();
      return EMPTY;
    }

    return this.http
      .post<IToken>(`${this.baseUrl}/auth/refreshToken`, { refreshToken }, httpOptions)
      .pipe(
        tap((response) => {
          if (response && response.accessToken) {
            this.setJwtToken(response.accessToken);
            const { userId, companyCode } = this.logCredentials();
            this.logService.createLoginLog({ userId, companyCode });
          }
        }),
      shareReplay(1)
    );
  }

  logout(): void {
    this.storageService.removeItem(this.STORAGE_KEY);
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      const { userId, companyCode } = this.logCredentials();
      this.logService.createLogoutLog({ userId, companyCode });
      this.currentUserSubject.next(null);
      this.router.navigate(['/login']);
    }
  }

  public forgetPassword(payload: IForgetPassword): Observable<any> {
    return this.http.post(`${this.publisherUrl}/publisher/forgotPassword`, JSON.stringify(payload), httpOptions);
  }
  
  public resetPassword(payload: IResetPassword): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/resetPassword`, payload, httpOptions);
  }

  public getCurrentUser(): Auth | null {
    return this.currentUserSubject.value;
  }

  public isLoggedIn(): boolean {
    return !!this.getJwtToken();
  }

  public hasRole(role: string): boolean {
    const currentUser = this.getCurrentUser();
    return currentUser !== null && currentUser.role === role;
  }

  public hasPermission(permission: number): boolean {
    const currentUser = this.getCurrentUser();
    return currentUser !== null && currentUser.permissions.includes(permission);
  }

  public getPermissions(): Permission[] {
    const currentUser = this.getCurrentUser();
    return currentUser.permissions;
  }

  public getJwtToken(): string | null {
    const currentUser = this.getCurrentUser();
    return currentUser ? currentUser.accessToken : null;
  }

  public setJwtToken(token: string): void {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      currentUser.accessToken = token;
      this.storageService.saveEncrypted(this.STORAGE_KEY, currentUser, this.ENCRYPTION_KEY);
      this.currentUserSubject.next(currentUser);
    }
  }

  public getRefreshToken(): string | null {
    const currentUser = this.getCurrentUser();
    return currentUser ? currentUser.refreshToken : null;
  }

  public setRefreshToken(token: string): void {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      currentUser.refreshToken = token;
      this.storageService.saveEncrypted(this.STORAGE_KEY, currentUser, this.ENCRYPTION_KEY);
      this.currentUserSubject.next(currentUser);
    }
  }

  public getCompanyCode(): number {
    return this.getCurrentUser()?.company.code;
  }

  public getCompanyCnpj(): string {
    return this.getCurrentUser()?.company.cnpj;
  }

  public updateUser(partialUser: Partial<Auth>): void {
    const updatedUser = { ...this.getCurrentUser(), ...partialUser };
    this.storageService.saveEncrypted(this.STORAGE_KEY, updatedUser, this.ENCRYPTION_KEY);
    this.currentUserSubject.next(updatedUser);
  }

  public isUserVirtsel(): boolean {
    return this.getCompanyCode() === 110 && this.getCurrentUser()?.role === Role.ADMINISTRATOR;
  }

  public logCredentials() {
    const { id: userId, company: { code: companyCode }} = this.getCurrentUser(); 
    return { userId, companyCode }
  }
}