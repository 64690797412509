import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccessControl } from 'src/app/interfaces/dtos/AccessControl';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { debounceTime } from 'rxjs/operators';
import { GenerateDate } from 'src/app/utils/generateDate.util';
import { AccessControlByDirectory } from 'src/app/interfaces/dtos/AccessControlByDirectory';
import { Formatter } from 'src/app/utils/formatter.util';
import { ChartOptions } from 'chart.js';
import { processValues } from '../../images/utils/mapped-values';
import { BaseChartDirective } from 'ng2-charts';

const { startDate, endDate } = GenerateDate.getDates();

export const defaultActiveFilters: AccessControlByDirectory.Params = {
  startDate: Formatter.formatDataPickerDate(startDate),
  endDate: Formatter.formatDataPickerDate(endDate),
  operations: "login",
  companies: ""
}

type View = 'average' | 'quantity' | 'deviation';

@Component({
  selector: 'app-access-control-directory',
  templateUrl: './access-control-directory.component.html',
  styleUrls: ['./access-control-directory.component.scss']
})
export class AccessControlDirectoryComponent implements OnInit, OnChanges {

  private NAMESPACE: Namespace = 'accessDirectory';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  activeView: BehaviorSubject<View> = new BehaviorSubject('average');
  daysBetween: number = 1;  
  data: BehaviorSubject<AccessControlByDirectory.Response<{ datasets: Array<Array<Number>>, labels: Array<string> }>> = new BehaviorSubject({
    filters: { operations: [], companies: [] },
    data: { datasets: [], labels: [] }
  });

  deviations;

  width = 75;

  barChartData = { datasets: [], labels: [] };
  barWidthProportion = '100%';
  isLessThan100  = false;
  colors = ['#FF0000', '#8B0000', '#000000'];
  filteredLogs = [];
  searchTerm: string = '';
  loading: boolean = true;
  error: boolean = false;
  
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
      padding: {
        top: 30,
        left: -5,
        right: -5,
        bottom: -5
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          callback: (value: string) => processValues({ key: 'groups', type: 'shortName', values: value })
        },
        gridLines: {
          display: false
        },
      }],
      yAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false
        },
      }]
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        color: 'black',
        offset: -2,
        font: { weight: 'bold', size: 10 },
        formatter: (value, context) => {
          return `${value}%`;
        }
      }
    }    
  };

  constructor(
    private authService: AuthService,
    private filterService: FilterService,
    private userService: UserService
  ) {
    this.filterService.setDefaultFilters<AccessControlByDirectory.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters 
    });

    this.filterService.getFiltersObservable<AccessControlByDirectory.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(500))
      .subscribe((af) => {

        // af.startDate - af.endDate // format: 'DD/MM/YYYY
        this.calculateDaysAndUpdateVariable(af);
        this.fetchData(af)
      });
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.updateBarProportion(this.data.value.data.datasets);
    this.createBarChart();
  }

  barChartLabelTreat() {
    return this.barChartData.labels.map((label) => processValues({ key: 'groups', type: 'shortName', values: label }));
  }

  calculateDaysAndUpdateVariable(af: { startDate: string; endDate: string }): void {
    const [startDay, startMonth, startYear] = af.startDate.split('/').map(Number);
    const [endDay, endMonth, endYear] = af.endDate.split('/').map(Number);
    const startDate = new Date(startYear, startMonth - 1, startDay); // Mês começa em 0
    const endDate = new Date(endYear, endMonth - 1, endDay);
    const timeDiff = endDate.getTime() - startDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    this.daysBetween = daysDiff;
  }

  createBarChart() {
    const { datasets, labels } = this.data.value.data;

    const formattedDatasets = datasets.map((data: number[], index) => {
      return {
        label: `Percentual`,
        data: `${(data[1]).toFixed(2)}`,
        backgroundColor: this.colors[index % this.colors.length],
      };
    });
  
    this.barChartData = {
      labels,
      datasets: formattedDatasets
    };
  }

  fetchData(activeFilters: AccessControlByDirectory.Params) {
    this.loading = true;

    return this.userService.getLogsByDirectory(activeFilters)
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        const output = new AccessControlByDirectory.Output(response);
        this.data.next(output.response);
        this.createBarChart();
        this.updateBarProportion(this.data.value.data.datasets);
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  isAdmin() {
    return this.authService.isUserVirtsel();
  }

  updateChartSize() {
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  updateBarProportion(datasets) {
    const datasetLength = (datasets.reduce((acc, current) => current.length + acc, 0))  || 0;
    const control = 14
    let newWidth = (datasetLength / control) * this.width
    if (newWidth < 20) newWidth = 20;
    if (newWidth > 100) newWidth = 100;
    this.barWidthProportion = `${newWidth}%`
    this.isLessThan100  = newWidth < 100;
    this.updateChartSize();
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDateMinusDays(1);
    return { startDate, endDate }
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    let newValues = values;
    this.filterService.updateFilters<AccessControl.Params>({
      namespace: this.NAMESPACE,
      key,
      values: newValues,
    });
  } 
}
