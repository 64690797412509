<div class="container-datas-picker">
  <div class="data-picker-container">
    <!-- DATA-PICKER-DATA-INICIO -->
    <div class="data-picker">
      <!-- TODO: Estilizar a label -->
      <label class="data-picker-label">
        Data Início
          <div class="data-picker-input-group">     
            <input
              type="text"
              class="data-picker-form-control"
              bsDatepicker
              #startDate="bsDatepicker"
              [bsConfig]="bsConfig"
              placeholder="dd/mm/yyyy"
              [(bsValue)]="startDateValue"
              (bsValueChange)="onChange('startDate')"
            >            
              <div class="data-picker-input-group-btn">
                <button 
                  class="data-picker-btn btn"
                  [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : getColors().secondaryColor }"
                  (click)="startDate.show()"
                  [attr.aria-expanded]="startDate.isOpen"
                  type="button"
              >
                <i class="fa fa-calendar" [ngStyle]="{ 'color': color ? color : '#fff' }"></i>
              </button>
            </div>
          </div>
      </label>
    </div>

    <!-- DATA-PICKER-DATA-FIM -->
    <div class="data-picker">
      <label class="data-picker-label">
        Data Fim
        <div class="data-picker-input-group">
          <input
            type="text"
            class="data-picker-form-control"
            #endDate="bsDatepicker"
            bsDatepicker
            [bsConfig]="bsConfig"
            placeholder="dd/mm/yyyy"
            [(bsValue)]="endDateValue"
            (bsValueChange)="onChange('endDate')"
          >
          <div class="data-picker-input-group-btn">
            <button 
              class="data-picker-btn btn"
              (click)="endDate.show()"
              [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : getColors().secondaryColor }"
              [attr.aria-expanded]="endDate.isOpen"
              type="button"
            >
              <i 
                class="fa fa-calendar" [ngStyle]="{ 'color': color ? color : '#fff' }"
              ></i>
            </button>
          </div>
        </div>
      </label>
    </div>
  </div>
</div>
