import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserGuideService } from 'src/app/services/user-guide.service';

@Component({
  selector: 'app-user-guide',
  templateUrl: './admin-guide.component.html',
  styleUrls: ['./admin-guide.component.sass']
})
export class AdminGuideComponent implements OnInit {
  fileUrl!: SafeResourceUrl;

  constructor(
    private adminGuideService: UserGuideService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const url = this.adminGuideService.getAdminUrl();
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}