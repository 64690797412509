import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { IUser } from '../interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccessControl } from '../interfaces/dtos/AccessControl';
import { AccessControlByDirectory } from '../interfaces/dtos/AccessControlByDirectory';
import { DetailLog } from '../interfaces/models/IDetailLogs';
import { UserMetricsLog } from '../interfaces/models/IUserMetricsLog';

interface CreateInput {
  fullName: string,
  username: string,
  email: string,
  password: string
  role: string,
  state: string,
  permissions: number[],
}

interface UpdateInput {
  fullName: string,
  username: string,
  email: string,
  role: string,
  state: string,
  permissions: number[],
}

export interface UserField {
  fieldName: string;
  columnName: string;
  values: string[];
}

export interface UserFieldUpdate {
  columnName: string;
  value: string;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly authUrl: string;
  private readonly baseUrl: string;
  users: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.authUrl = environment.authUrl;
    this.baseUrl = environment.baseUrl;
  }

  private toMap(users: any): IUser[] {
    const usersMapped = []
    users.forEach((user) => {
      usersMapped.push({
        id: user.id,
        username: user.username,
        fullName: user.fullName,
        role: user.role,
        permissions: user.permissions,
        email: user.email,
        lastSignIn: user.lastSignIn,
        company: user.company
      });
    });
    return usersMapped;
  }

  getUsers() {
    return this.users.value;
  }

  updateUsers(users: IUser[]) {
    this.users.next(users);
  }

  getUsersAll() {
    return this.http.get<IUser[]>(`${this.authUrl}/users?cnpj=${this.authService.getCompanyCnpj()}`);
  }

  getById({ id }: { id: number }): IUser {
    return this.users.value.find((user) => user.id === id);
  }

  create({ input }: { input: CreateInput }) {
    const response = this.http
      .post(`${this.authUrl}/users`, { ...input, cnpj: this.authService.getCompanyCnpj() }, httpOptions)
      .pipe(
        tap((response: any) => {
          const currentUsers = this.getUsers();
          const merge = [...currentUsers, ...this.toMap([response])];
          this.updateUsers(merge);
        })
      )
    return response;
  }

  update({ id, input }: { id: number, input: UpdateInput }) {
    this.http
      .patch<IUser>(`${this.authUrl}/users/${id}`, input, httpOptions)
      .subscribe((response) => {
        const currentUsers = this.getUsers().filter((user) => user.id !== id);
        const merge = [...currentUsers, ...this.toMap([response])]
        this.updateUsers(merge);
      });
  }

  delete({ id }: { id: number }): void {
    this.http.delete(`${this.authUrl}/users/${id}`, httpOptions).subscribe(() => {
      this.updateUsers(this.getUsers().filter((user) => user.id !== id))
    });
  }

  createPassword(payload: { userId: number, password: string }): Observable<any> {
    const { userId, password } = payload;
    const response = this.http.patch(`${this.authUrl}/users/createPassword/${userId}`, { password }, httpOptions);
    return response;
  }

  getUserInfo(userId: number): Observable<{ data: UserField[] }> {
    return this.http.get<{ data: UserField[] }>(`${this.authUrl}/auth/users/update/${userId}`, httpOptions);
  }

  updateUserInfo(userId: number, payload: UserFieldUpdate[]): Observable<any> {
    return this.http.patch<any>(`${this.authUrl}/auth/users/update/${userId}`, { data: payload }, httpOptions);
  }
  
  getLogs(activeFilters: AccessControl.Params): Observable<AccessControl.Response<UserMetricsLog[]>> {
    const { id } = this.authService.getCurrentUser();
    return this.http.post<AccessControl.Response<UserMetricsLog[]>>(`${this.authUrl}/vandalism/logs/list/${id}`, activeFilters, httpOptions)
  }

  getLogsByDirectory(activeFilters: AccessControlByDirectory.Params): Observable<AccessControlByDirectory.Response<{ datasets: Array<Array<any>>, labels: Array<string> }>> {
    const { id } = this.authService.getCurrentUser();
    return this.http.post<AccessControlByDirectory.Response<{ datasets: Array<Array<any>>, labels: Array<string> }>>(`${this.baseUrl}/vandalism/logs/directors`, { userId: id, ...activeFilters}, httpOptions)
  }

  getLogsByUser(userId: number, activeFilters: AccessControl.Params): Observable<AccessControl.Response<DetailLog[]>> {
    return this.http.post<AccessControl.Response<DetailLog[]>>(`${this.authUrl}/vandalism/logs/user/${userId}`, activeFilters, httpOptions)
  }

  getPolicy(){
    return this.http.get<{data : string}>(`${this.authUrl}/vandalism/policy`, httpOptions)
  }

  updatePolicy(userId: number){
    return this.http.get<{data : boolean}>(`${this.authUrl}/vandalism/policy/${userId}`, httpOptions)
  }
}
