import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { slideInOutAnimation, fadeInOutAnimation } from 'src/app/animations/animations';
import { IEffectiveness } from 'src/app/interfaces/models/IEffectiveness';
import { Namespace } from 'src/app/services/filter.service';
import { GenerateDate } from 'src/app/utils/generateDate.util';
import { icons } from 'src/app/utils/icons';
import { environment } from 'src/environments/environment';

export interface ActivityFilters {
  initiatives?: string
  regionals?: string
  directors?: string
  cities?: string
  states?: string
  companies?: string
  clusters?: string
  subclusters?: string
  technicians?: string
  startDate?: string;
  endDate?: string;
}

export const hierarchyRanking = {
  regionals: { hierarchy: 0 },
  directors: { hierarchy: 1 },
  states: { hierarchy: 2 },
  clusters: { hierarchy: 3 },
  subclusters: { hierarchy: 4 },
  cities: { hierarchy: 5 },
  companies: { hierarchy: 5 },
  technicians: { hierarchy: 5 },
  initiatives: { hierarchy: null },
}

export const defaultActiveFilters: ActivityFilters = {
  initiatives: 'PEAD',
  regionals: '',
  directors: '',
  states: '',
  clusters: '',
  subclusters: '',
  cities: '',
  companies: '',
  technicians: '',
  startDate: '', 
  endDate: ''
};

export interface DropdownFiltersNew {
  initiatives: string[];
  states: string[];
  regionals: string[];
  directors: string[],
  cities:string[],    
  companies:string[],
  clusters:string[],
  subclusters:string[],
  technicians:string[],
}

export interface selectedFiltersNew {
  initiatives: string[];
  states: string[];
  regionals: string[];
  directors: string[],
  cities: string[],    
  companies: string[],
  clusters: string[],
  subclusters: string[],
  technicians: string[],
}

@Component({
  selector: 'app-efetividade',
  templateUrl: './efetividade.component.html',
  styles: [],
  animations: [slideInOutAnimation, fadeInOutAnimation]
})
export class EfetividadeComponent implements OnInit {
  NAMESPACE: Namespace = 'effectiveness';

  effectivenessData: BehaviorSubject<IEffectiveness[]> = new BehaviorSubject([]);  
  activeFilters: BehaviorSubject<ActivityFilters> = new BehaviorSubject(defaultActiveFilters);
  selectedFilters: BehaviorSubject<selectedFiltersNew> = new BehaviorSubject<selectedFiltersNew>({
    initiatives: ['PEAD'],    
    regionals: [],
    directors: [],
    cities: [],
    states: [],
    companies: [],
    clusters: [],
    subclusters: [],
    technicians: [], 
  });

  dropdownFilters: BehaviorSubject<DropdownFiltersNew> = new BehaviorSubject<DropdownFiltersNew>({
    initiatives: [],    
    regionals: [],
    directors: [],
    cities: [],
    states: [],
    companies: [],
    clusters: [],
    subclusters: [],
    technicians: [],    
  });

  averageEffectiveness: number = 0; 
  currentPage: number = 1;
  totalPages: number = 1; 
  loading: boolean = false;
  error: boolean = false;
  isDivVisible: boolean = false;
  icons = icons;
  iconBackHome = '/assets/icons/back-home.png';
  iconBackHomeWhite = '/assets/icons/back-home-white.png';

  constructor(private http: HttpClient) {
    const { startDate, endDate } = GenerateDate.getDates();
  }

  ngOnInit(): void {         
    this.resetFilters();
    this.getInitiative(0, this.activeFilters.value);
  }

  resetFilters(): void {    
    this.activeFilters.next({ ...defaultActiveFilters });
  }
  
  onPageChange(newPage: number) {
    this.getInitiative(newPage - 1, this.activeFilters.value);
  }  

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;
  }    

  getInitiative(page: number, filters: ActivityFilters) {
    this.loading = true;
    this.error = false;
    const baseUrl = environment.baseUrl;
    const url = `${baseUrl}/vandalism/initiative`;

    const body = {
      initiatives: filters.initiatives || '',
      regionals: filters.regionals || '',
      directors: filters.directors || '',
      cities: filters.cities || '',
      states: filters.states || '',
      companies: filters.companies || '',
      clusters: filters.clusters || '',
      subclusters: filters.subclusters || '',
      technicians: filters.technicians || '',
      startDate: filters.startDate,
      endDate: filters.endDate,
      pages: page
    };

    console.log(body);
    // console.log(filters.startDate,filters.endDate);

    return this.http
      .post<any>(url, body)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        if (response) {
          this.effectivenessData.next(response.body);

          const newDropdownFilters: DropdownFiltersNew = {
            initiatives: response.filters.initiatives || [],
            regionals: response.filters.regionals || [],
            directors: response.filters.directors || [],
            cities: response.filters.cities || [],
            states: response.filters.states || [],
            companies: response.filters.companies || [],
            clusters: response.filters.clusters || [],
            subclusters: response.filters.subclusters || [],
            technicians: response.filters.technicians || []
          };

          const newSelectedFilters: selectedFiltersNew = {
            initiatives: newDropdownFilters.initiatives.length === 1 ? [newDropdownFilters.initiatives[0]] : [],
            regionals: newDropdownFilters.regionals.length === 1 ? [newDropdownFilters.regionals[0]] : [],
            directors: newDropdownFilters.directors.length === 1 ? [newDropdownFilters.directors[0]] : [],
            cities: newDropdownFilters.cities.length === 1 ? [newDropdownFilters.cities[0]] : [],
            states: newDropdownFilters.states.length === 1 ? [newDropdownFilters.states[0]] : [],
            companies: newDropdownFilters.companies.length === 1 ? [newDropdownFilters.companies[0]] : [],
            clusters: newDropdownFilters.clusters.length === 1 ? [newDropdownFilters.clusters[0]] : [],
            subclusters: newDropdownFilters.subclusters.length === 1 ? [newDropdownFilters.subclusters[0]] : [],
            technicians: newDropdownFilters.technicians.length === 1 ? [newDropdownFilters.technicians[0]] : []
          };      

          this.dropdownFilters.next(newDropdownFilters);
          this.selectedFilters.next(newSelectedFilters);
          this.activeFilters.next(this.buildActiveFilters(newSelectedFilters));

          this.averageEffectiveness = response.averageEffectiveness;
          this.totalPages = response.pagesNumber;
          this.currentPage = page + 1;
        }
      });
  }

  buildActiveFilters(selectedFilters: selectedFiltersNew): ActivityFilters {
    return {
      initiatives: selectedFilters.initiatives.length > 0 ? selectedFilters.initiatives.join(',') : '',
      regionals: selectedFilters.regionals.length > 0 ? selectedFilters.regionals.join(',') : '',
      directors: selectedFilters.directors.length > 0 ? selectedFilters.directors.join(',') : '',
      states: selectedFilters.states.length > 0 ? selectedFilters.states.join(',') : '',
      clusters: selectedFilters.clusters.length > 0 ? selectedFilters.clusters.join(',') : '',
      subclusters: selectedFilters.subclusters.length > 0 ? selectedFilters.subclusters.join(',') : '',
      cities: selectedFilters.cities.length > 0 ? selectedFilters.cities.join(',') : '',
      companies: selectedFilters.companies.length > 0 ? selectedFilters.companies.join(',') : '',
      technicians: selectedFilters.technicians.length > 0 ? selectedFilters.technicians.join(',') : '',
      endDate: this.activeFilters.value.endDate,
      startDate: this.activeFilters.value.startDate,
    };
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getLastMonthRange();
    return { startDate, endDate };
  }

  onChange(event: string ) {
    const [key, value] = event.split(':');
    const currentFilters = { ...this.activeFilters.value };    
    currentFilters[key] = value;

    // Atualize apenas o valor do filtro específico (startDate ou endDate)
    if (key === 'startDate') {
      currentFilters.startDate = value;
      // Preserve endDate atual, se existir
      if (!currentFilters.endDate) {
        currentFilters.endDate = this.activeFilters.value.endDate;
      }
    } else if (key === 'endDate') {
      currentFilters.endDate = value;
      // Preserve startDate atual, se existir
      if (!currentFilters.startDate) {
        currentFilters.startDate = this.activeFilters.value.startDate;
      }
    } else {
      currentFilters[key] = value;
    }  

    if (value === '') {
      const keyHierarchy = hierarchyRanking[key]?.hierarchy;

      if (keyHierarchy !== undefined && keyHierarchy !== null) {
          Object.keys(hierarchyRanking).forEach(hierarchyKey => {
              const hierarchyLevel = hierarchyRanking[hierarchyKey].hierarchy;
              if (hierarchyLevel !== null && hierarchyLevel > keyHierarchy) {
                  currentFilters[hierarchyKey] = '';
              }
          });
      }
    }
    console.log(currentFilters.endDate,currentFilters.startDate);
    this.activeFilters.next(currentFilters);
    this.getInitiative(0, currentFilters);
  }  
}
