export namespace VandalismTicketPopup {
  export interface ImagePopupParams {
    ticketId: number;
    isFirstImage: boolean;
  }

  export interface TicketPopupParams {
    ticketId: number;
    guid: string;    
  } 

  export interface ReportImagePopupParams {
    ticketId: number,
    imageIndex: number,
    validation: boolean
  }

  export interface ReportIncidentPopupParams {
    guid: string,
    occurrence: string
  }
  
  export interface Photo {
    lat: number | null;
    lng: number | null;
    imageIndex: number;
  }
  
  export interface Location {
    forEach(arg0: (location: any) => void): unknown;
    ticketId: number;
    lat: number | null;
    lng: number | null;
    photos: Photo[];
  }

  export interface DisplayDataPopup {
    isLegacy: string,
    ticketId: number,
    taskIdIncident: string,
    ticketSystemOrigin: string,
    technicianId: string,
    address: string,
    district: string,
    reportedDate: string,
    routeDate: string,
    cause: string,
    operational: string,
    status: string,
    company: string,
    quantity: number,
    BO: string,
    isPead: boolean,
    effectiveness: number,
    decrease: number,
    daysAfter: number,
    daysBefore: number,
    ticketsAfter: number,
    ticketsBefore: number
  }
}
